import React from 'react';

const ContainerWrapper = ({
  className,
  sectionClass,
  children,
  ...otherProps
}) => {
  return (
    <main className={`${className} flex-1 bg-white shadow rounded-lg p-1 py-4`}>
      <section
        aria-labelledby="primary-heading"
        className={`min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:order-last overflow-y-auto text-gray-700 container-wrapper`}
        {...otherProps}
      >
        {children}
      </section>
    </main>
  );
};

export default ContainerWrapper;
