export class CustomGalleryTool {
  constructor({ data, config }) {
    this.data = data;
    this.config = config;
    this.wrapper = undefined;
  }

  static render(gallery = null, wrapper) {
    this._createImageGallery(gallery, wrapper);
  }

  static _createImageGallery(_gallery, wrapper) {
    if (!_gallery?.length) {
      const button = document.createElement('button');
      button.id = 'upload-image';
      if (wrapper) {
        document.getElementById(wrapper).innerHTML = '';
        document.getElementById(wrapper).appendChild(button);
        button.innerHTML = `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.15 13.628A7.749 7.749 0 0 0 10 17.75a7.74 7.74 0 0 0 6.305-3.242l-2.387-2.127-2.765 2.244-4.389-4.496-3.614 3.5zm-.787-2.303l4.446-4.371 4.52 4.63 2.534-2.057 3.533 2.797c.23-.734.354-1.514.354-2.324a7.75 7.75 0 1 0-15.387 1.325zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path></svg> Select an Image`;

        button.getElementsByTagName('svg')[0].style.marginRight = '0.2rem';
      }

      return;
    }

    const gallery = document.createElement('div');
    gallery.classList.add('editor__image-gallery');

    _gallery.forEach((url) => {
      const img = document.createElement('img');
      img.src = url.source;
      gallery.appendChild(img);
    });
    if (wrapper) {
      document.getElementById(wrapper).innerHTML = '';
      document.getElementById(wrapper).appendChild(gallery);
    }
  }

  save(blockContent) {
    const image = blockContent.querySelector('img');
    const caption = blockContent.querySelector('input');

    return {
      url: image?.src ?? '',
      caption: caption?.value ?? ''
    };
  }
}
