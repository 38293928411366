import React, { Fragment, useState, useEffect } from 'react';
import Loader from 'react-js-loader';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import NotFound from '../../assets/images/notFound.png';
import moment from 'moment';
import Pagination from '../../components/pagination/pagination';
import APIService from '../../APIService';

const Logs = () => {
  const pages = [{ name: 'Logs', href: '/logs', current: true }];
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const GetLogs = async (params) => {
    const result = await APIService.Get('activity-logs', { ...params });
    setLoading(false);
    setLogs(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetLogs({
      page: newPage
    });
  };

  useEffect(() => {
    setLoading(true);
    GetLogs({ page: 1 });
  }, []);

  return (
    <Fragment>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                <Fragment>
                  {logs.length ? (
                    logs.map((log, index) => (
                      <li key={index}>
                        <div className="block hover:bg-gray-50 flex justify-between">
                          <div className="px-4 py-5 flex items-center sm:px-6 w-full">
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    {log.log_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    {log.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    by {log.causer_first_name}{' '}
                                    {log.causer_last_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    at{' '}
                                    {moment(log.cretaed_at).format(
                                      'DD.MM.YYYY hh:mm:ss'
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Logs;
