import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import './select.css';

const animatedComponents = makeAnimated();

const SelectCustom = ({
  label,
  className,
  options = [],
  setSelectedOption,
  defaultValue,
  isClearable = true
}) => {
  return (
    <div className={`${className} w-full mb-3`}>
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <Select
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        components={animatedComponents}
        isSearchable={true}
        isClearable={isClearable}
        value={defaultValue}
        onChange={setSelectedOption}
      />
    </div>
  );
};

export default SelectCustom;
