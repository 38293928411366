import axios from 'axios';
import LocalStorageService from './LocalStorageService';
import apiConfig from './config/api.config.js';

let calledOnce = false;

axios.interceptors.request.use(
  (config) => {
    if (config.url !== `${apiConfig.apiUrl}/login`) {
      const token = LocalStorageService.get('access_token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !calledOnce
    ) {
      originalRequest._retry = true;
      calledOnce = true;
    }
    return Promise.reject(error);
  }
);
