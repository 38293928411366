import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Loader from 'react-js-loader';
import NotFound from '../../assets/images/notFound.png';
import List from '../../components/stackedList/stackedList';
import Pagination from '../../components/pagination/pagination';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import APIService from '../../APIService';

const Informs = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const pages = [{ name: 'Informs', href: '/informs', current: true }];
  const [news, setNews] = useState([]);
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });
  const [inform, setInform] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const GetAllNews = async () => {
    const result = await APIService.Get('news-from-readers');
    setLoading(false);
    setNews(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  const DeleteNews = async () => {
    const result = await APIService.Delete(inform, 'news-from-readers');
    setShowAlert(true);
    setAlert({
      header: 'Successfully deleted!',
      message: 'This news is no longer available.'
    });
    GetAllNews({ page: params.current_page });
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetAllNews({
      page: newPage
    });
  };

  useEffect(() => {
    setLoading(true);
    GetAllNews();
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete news"
        deleteFun={DeleteNews}
      >
        Are you sure you want to delete this news? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
            </div>

            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                <Fragment>
                  {news.length ? (
                    news.map((el) => (
                      <li key={el.id}>
                        <div className="block hover:bg-gray-50 flex justify-between">
                          <div
                            className="px-4 py-5 flex items-center sm:px-6 w-full"
                            onClick={() =>
                              history.push(`/informs/details/${el.id}`)
                            }
                          >
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    {el.title}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    author: {el.full_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                              {' '}
                              See details{' '}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setInform(el.id);
                            }}
                            className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Informs;
