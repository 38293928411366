import React, { Fragment } from 'react';
import Input from '../formInput/formInput';

const SeoAccordion = ({ data, handleState }) => {
  return (
    <Fragment>
      <Input
        name="seo_title"
        label="Title*"
        className="mb-3"
        type="text"
        value={data.seo_title}
        onChange={handleState}
      />
      <Input
        name="seo_description"
        label="Description"
        type="text"
        className="mb-3"
        value={data.seo_description}
        onChange={handleState}
      />
      <Input
        name="seo_keywords"
        label="Keywords"
        type="text"
        className="mb-3"
        value={data.seo_keywords}
        onChange={handleState}
      />
    </Fragment>
  );
};

export default SeoAccordion;
