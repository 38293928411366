import React, { Fragment, useState, useEffect } from 'react';
import Loader from 'react-js-loader';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/solid';
import APIService from '../../APIService';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Pagination from '../../components/pagination/pagination';
import NotFound from '../../assets/images/notFound.png';

const InfoGuide = () => {
  const history = useHistory();
  const pages = [{ name: 'Info guide', href: '/info-guide', current: true }];

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetArticles({
      page: newPage
    });
  };

  const GetArticles = async (params) => {
    const result = await APIService.Get('articles', {
      ...params,
      info_guide: 'Info vodič'
    });
    setLoading(false);
    setArticles(result.data);
    const { current_page, last_page, per_page, total } = result.meta;

    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  useEffect(() => {
    setLoading(true);
    GetArticles({ page: 1 });
  }, []);

  return (
    <div className="p-4 h-full">
      {loading ? (
        <div className="h-full flex items-center justify-center">
          <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
        </div>
      ) : (
        <Fragment>
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
            <Breadcrumb pages={pages} />
            <div className="flex-shrink-0">
              <AddButton onClick={() => history.push('/add-article')}>
                Create new article
              </AddButton>
            </div>
          </div>
          <div
            className="relative pb-20"
            style={{ height: 'fit-content', minHeight: '90%' }}
          >
            <List url="">
              <Fragment>
                {articles.length ? (
                  articles.map((article) => (
                    <li
                      key={article.id}
                      onClick={() =>
                        history.push(`/edit-article/${article.id}`)
                      }
                    >
                      <div className="block hover:bg-gray-50">
                        <div
                          className="px-4 py-4 flex items-center sm:px-6"
                          style={{
                            backgroundColor: moment(
                              article.published_at
                            ).isAfter(new Date())
                              ? '#bbcef738'
                              : '#fff'
                          }}
                        >
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate flex align-center">
                              <div className="flex-shrink-0 mr-5">
                                <img
                                  className="h-12 w-12 rounded-full object-cover"
                                  alt=""
                                  src={
                                    JSON.parse(article.thumbnail_image).data
                                      .files[0].url
                                  }
                                />
                              </div>
                              <div>
                                <div className="flex text-sm">
                                  <p className="font-medium text-indigo-600 truncate">
                                    {article.title}
                                  </p>
                                  <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                                    {' '}
                                    {`${article.created_by?.first_name} ${article.created_by?.last_name}`}
                                  </p>
                                </div>
                                <div className="mt-2 flex">
                                  <div className="flex items-center text-sm text-gray-500">
                                    <CalendarIcon
                                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <p>
                                      {moment(article.published_at).isAfter(
                                        new Date()
                                      )
                                        ? 'Coming up '
                                        : 'Published at '}
                                      <time dateTime={'2020-01-07'}>
                                        {moment(article.published_at).format(
                                          'DD-MM-YYYY HH:MM'
                                        )}
                                      </time>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                              <div className="flex overflow-hidden -space-x-1">
                                <p className="text-sm text-gray-500">
                                  Number of comments{' '}
                                  <span className="font-medium">
                                    {article.comment_count}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0">
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <img className="img-not-found" src={NotFound} />
                )}
              </Fragment>
            </List>
            <Pagination params={params} getPageNumber={getPageNumber} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default InfoGuide;
