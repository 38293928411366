import React, { Fragment, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import APIService from '../../APIService';
import ContainerWrapper from '../../layout/ContainerWrapper/ContainerWrapper';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Input from '../../components/formInput/formInput';
import Textbox from '../../components/formInput/textbox';
import AddButton from '../../components/buttons/AddButton';
import DeleteButton from '../../components/buttons/DeleteButton';
import SubmitButton from '../../components/buttons/SubmitButton';
import EditButton from '../../components/buttons/EditButton';
import Accordion from '../../components/accordion/accordion';
import DatetimePicker from '../../components/datetimePicker/datetimePicker';
import Success from '../../components/alerts/success';
import Error from '../../components/alerts/error';
import DeleteModal from '../../components/deleteModal/deleteModal';
import { TrashIcon } from '@heroicons/react/outline';

const AddPoll = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const [showAside, setShowAside] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const pages = [
    {
      name: 'Polls',
      href: '/polls',
      current: false
    },
    {
      name: location.pathname.includes('edit-poll') ? 'Edit poll' : 'Add poll',
      href: location.pathname.includes('edit-poll')
        ? `/edit-poll/${params.id}`
        : '/add-poll',
      current: true
    }
  ];

  const [poll, setPoll] = useState({
    id: null,
    question: '',
    description: '',
    slug: ''
  });
  const [publishedDate, setPublishedDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [formValues, setFormValues] = useState([{ text: '' }]);

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { text: '' }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const AddPoll = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Insert('polls', {
        question: poll.question,
        description: poll.description,
        published_at: publishedDate,
        expires_at: expiredDate,
        answers: formValues.map((el) => el.text)
      });
      setButtonClicked(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully added!',
        message: 'You have successfully added new poll.'
      });
      history.push('/polls');
    } catch (err) {
      setButtonClicked(false);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const EditPoll = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Update(poll.id, 'polls', {
        question: poll.question,
        description: poll.description,
        published_at: publishedDate,
        expires_at: expiredDate,
        answers: formValues.map((el) => el.text)
      });
      setButtonClicked(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully edited!',
        message: 'You have successfully edit poll.'
      });
      setTimeout((_) => history.push('/polls'), 1000);
    } catch (err) {
      setButtonClicked(false);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const DeletePoll = async () => {
    try {
      const result = await APIService.Delete(poll.id, 'polls');
      setAlert({
        header: 'Successfully deleted!',
        message: 'This poll is no longer available.'
      });
      setShowAlert(true);
      setTimeout((_) => history.push('/polls'), 1000);
    } catch (err) {
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const GetPollById = async (id) => {
    const result = await APIService.GetById(`${id}/show`, 'polls');
    setPoll({
      id: result.data.id,
      question: result.data.question,
      description: result.data.description ?? '',
      slug: result.data.slug ?? ''
    });
    setPublishedDate(
      result.data.published_at ? new Date(result.data.published_at) : null
    );
    setExpiredDate(
      result.data.expires_at ? new Date(result.data.expires_at) : null
    );
    setFormValues(
      result.data.answers.map((el) => {
        return { text: el.answer };
      })
    );
  };

  useEffect(() => {
    if (params.id) GetPollById(params.id);
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        header="Delete poll"
        deleteFun={DeletePoll}
      >
        Are you sure you want to delete this poll? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <div className="-ml-4 lg:-mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
        <Breadcrumb pages={pages} />
        <AddButton
          className="lg:hidden"
          onClick={() => setShowAside(!showAside)}
        />
      </div>
      <div className="flex-1 flex">
        <ContainerWrapper className="p-9 pt-12">
          <Textbox
            name="question"
            label="Question*"
            type="text"
            className="mb-8"
            value={poll.question}
            onChange={({ target }) => {
              setPoll({
                ...poll,
                question: target.value
              });
            }}
          />

          {formValues.map((element, index) => (
            <div className="flex items-center" key={index}>
              <Input
                name="text"
                label={`Answer ${index + 1}`}
                type="text"
                className="mb-8 w-full"
                value={element.text || ''}
                onChange={(e) => handleChange(index, e)}
              />
              {index ? (
                <TrashIcon
                  className="w-8 h-8 text-red-500 mb-2 ml-6 cursor-pointer"
                  onClick={() => removeFormFields(index)}
                />
              ) : null}
            </div>
          ))}

          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none w-28 justify-center"
            onClick={() => addFormFields()}
          >
            + Add answer
          </button>
        </ContainerWrapper>
        <aside
          className={`${
            isMobile && showAside
              ? 'block absolute z-10 h-full right-0'
              : 'hidden lg:block ml-6'
          }  w-96 overflow-x-hidden bg-white shadow rounded-lg`}
        >
          {location.pathname.includes('edit-poll') ? (
            <div className="flex justify-between pt-3.5 px-4">
              <DeleteButton
                className="mr-6"
                onClick={() => {
                  //   setCategory(category);
                  setOpenModal(true);
                }}
              >
                Delete poll
              </DeleteButton>
              <EditButton
                buttonClicked={buttonClicked}
                valid={
                  poll.question &&
                  formValues.every((el) => el.text !== '') &&
                  publishedDate
                }
                onClick={EditPoll}
              >
                Save changes
              </EditButton>
            </div>
          ) : (
            <div className="flex justify-end pt-3.5 px-4">
              <SubmitButton
                buttonClicked={buttonClicked}
                valid={
                  poll.question &&
                  formValues.every((el) => el.text !== '') &&
                  publishedDate
                }
                onClick={AddPoll}
              >
                Add poll
              </SubmitButton>
            </div>
          )}
          <Accordion header="OPTIONS" expanded={true}>
            <Textbox
              name="description"
              label="Description"
              type="text"
              className="mb-8"
              value={poll.description}
              onChange={({ target }) => {
                setPoll({
                  ...poll,
                  description: target.value
                });
              }}
            />
            <DatetimePicker
              className="mb-8"
              label="Published at*"
              date={publishedDate}
              setDate={setPublishedDate}
            />
            <DatetimePicker
              className="mb-8"
              label="Expires at"
              date={expiredDate}
              setDate={setExpiredDate}
            />
            {poll.id ? (
              <Input
                label="Slug"
                name="slug"
                className="mb-3"
                value={poll.slug}
                handleChange={({ target }) => {
                  setPoll({
                    ...poll,
                    slug: target.value
                  });
                }}
              />
            ) : null}
          </Accordion>
        </aside>
      </div>
    </Fragment>
  );
};

export default AddPoll;
