import axios from 'axios';
import config from '../src/config/api.config';

const mapQueryParamsToUrl = (qp) =>
  Object.keys(qp).map((key) => `${key}=${qp[key]}`);

const correctFormatForQueryUrl = (qp) => {
  if (qp === null) {
    return '';
  }
  const qpAsStr = mapQueryParamsToUrl(qp);
  const returnValue = qpAsStr.length === 0 ? '' : `?${qpAsStr.join('&')}`;
  return returnValue;
};

const handleError = (error) => {
  if (error?.response) {
    return error;
  } else {
    return { error: 'An unknown error happened' };
  }
};

const ApiService = {
  Get: async (route, qp = {}) => {
    const queryString = correctFormatForQueryUrl(qp);
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(
          `${config.apiUrlBuka}/${route}${queryString}`
        );
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  GetById: async (id, route) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(`${config.apiUrlBuka}/${route}/${id}`);
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  Insert: async (route, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post(`${config.apiUrlBuka}/${route}`, data);
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  Update: (id, route, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.put(
          `${config.apiUrlBuka}/${route}/${id}`,
          data
        );
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  Delete: async (id, route) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.delete(
          `${config.apiUrlBuka}/${route}/${id}`
        );
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  }
};

export default ApiService;
