import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';

import './pagination.css';

const TablePaginationAction = (totalPages, page, handleChangePage) => {
  return (
    <Pagination
      count={totalPages}
      showFirstButton
      showLastButton
      page={page}
      onChange={handleChangePage}
      siblingCount={0}
      variant="outlined"
      shape="rounded"
      color="primary"
    />
  );
};

const CustomPagination = ({ params, getPageNumber, defaultPage = null }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(params.per_page ?? 10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(1);
  }, [defaultPage]);

  return (
    <TablePagination
      className="table__navigation"
      rowsPerPageOptions={[]}
      component="div"
      count={params.total}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={() =>
        params.last_page <= 1
          ? null
          : TablePaginationAction(params.last_page, page, handleChangePage)
      }
    />
  );
};

export default CustomPagination;
