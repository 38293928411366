import React from 'react';
import { CircularProgress } from '@material-ui/core';

const EditButton = ({
  onClick,
  className,
  valid = true,
  buttonClicked = false,
  children,
  ...otherProps
}) => {
  return (
    <div className={className}>
      {valid && !buttonClicked ? (
        <button
          type="button"
          className={`${className} inline-flex items-center px-5 py-1.5 border border-transparent text-sm font-medium rounded text-white bg-green-600-custom hover:bg-green-600-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400  justify-center`}
          onClick={buttonClicked ? null : onClick}
          {...otherProps}
        >
          <span> {children} </span>
        </button>
      ) : (
        <button
          variant="contained"
          disabled
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-9 py-1.5 bg-gray-200 text-base font-medium text-gray-400 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm cursor-not-allowed  justify-center"
          {...otherProps}
        >
          {buttonClicked ? (
            <CircularProgress disableShrink color="inherit" size="1.2rem" />
          ) : (
            children
          )}
        </button>
      )}
    </div>
  );
};

export default EditButton;
