import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../components/functions/functions';
// import { sidebarNavigation } from './menu';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

// import { CogIcon, CollectionIcon, HomeIcon } from '@heroicons/react/outline';
import * as icons from '@heroicons/react/outline';

import './sidebar.css';

const Sidebar = ({ t, menu }) => {
  const location = useLocation();
  const [current, setCurrent] = useState(false);
  const [sidebarNavigation, setSidebarNavigation] = useState([]);

  useEffect(() => {
    if (sidebarNavigation.find((el) => el.url === location?.pathname)) {
      setCurrent(location.pathname);
    }

    // setSidebarNavigation(menu);
    setSidebarNavigation(
      menu?.map((x) => {
        return { ...x, icon: icons[x.icon] };
      })
    );
  }, []);

  return (
    <div className="hidden w-28 overflow-y-auto md:block sidebar">
      <div className="w-full py-6 flex flex-col items-center">
        <div className="flex-shrink-0 flex items-center">
          <img
            className="h-8 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
            alt="Workflow"
          />
        </div>
        <div className="flex-1 mt-6 w-full px-2 space-y-1">
          {sidebarNavigation?.map((item) =>
            item.children.length ? (
              <Menu
                as="div"
                key={item.name}
                className="inline-block text-left w-full"
              >
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="w-full focus:outline-none">
                        <div
                          onClick={() => setCurrent(item.url)}
                          className={classNames(
                            current === item.url
                              ? 'sidebar-item--active text-white'
                              : 'text-indigo-100 sidebar-item hover:text-white',
                            'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium focus:outline-none'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              current === item.url
                                ? 'text-white'
                                : 'text-indigo-300 group-hover:text-white',
                              'h-6 w-6'
                            )}
                            aria-hidden="true"
                          />

                          <span className="mt-2 text-center">{item.name}</span>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="z-10 mx-3 origin-top absolute right-0 left-24 -mt-20 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none
                      w-max sidebar__menu-items"
                      >
                        <div className="py-1">
                          {item.children.map((child) => (
                            <Menu.Item key={child.name}>
                              {({ active }) => (
                                <NavLink
                                  key={child.name}
                                  to={child.url}
                                  className={classNames(
                                    active
                                      ? 'sidebar__menu-item--active'
                                      : 'sidebar__menu-item',
                                    'block px-7 py-2 text-sm'
                                  )}
                                >
                                  {child.name}
                                </NavLink>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            ) : (
              <NavLink
                key={item.name}
                to={item.url}
                className={classNames(
                  current === item.url
                    ? 'sidebar-item--active text-white'
                    : 'text-indigo-100 sidebar-item hover:text-white',
                  'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                )}
                onClick={() => setCurrent(item.url)}
              >
                <item.icon
                  className={classNames(
                    current === item.url
                      ? 'text-white'
                      : 'text-indigo-300 group-hover:text-white',
                    'h-6 w-6'
                  )}
                  aria-hidden="true"
                />

                <span className="mt-2 text-center">{item.name}</span>
              </NavLink>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ ...state.menu });

export default withNamespaces()(connect(mapStateToProps, null)(Sidebar));
