import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import { CalendarIcon } from '@heroicons/react/solid';
import APIService from '../../APIService';
import List from '../../components/stackedList/stackedList';
import SelectAsync from '../../components/multiselect/selectAsync';
import NotFound from '../../assets/images/notFound.png';

const MostRead = () => {
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState(null);
  const [changeArticle, setChangeArticle] = useState(null);

  const GetArticles = async () => {
    const result = await APIService.Get('editorial/most-read-articles');
    setArticles(result.data);
  };

  const ReplaceArticle = async (replaced, replace) => {
    const result = await APIService.Insert(
      'editorial/set-article-as-most-read',
      {
        remove_from_most_read: replaced,
        add_as_most_read: replace
      }
    );
    GetArticles();
  };

  const ChangeOrder = async (switch_id, for_id) => {
    const result = await APIService.Insert(
      'editorial/most-read-articles-change-order',
      {
        switch_this_article_order_id: switch_id,
        for_that_article_order_id: for_id
      }
    );
    GetArticles();
  };

  const GetArticleIdByOrder = (order) => {
    return articles.find((ar) => ar.most_read_article_order === parseInt(order))
      .id;
  };

  useEffect(() => {
    GetArticles();
  }, []);

  return (
    <Fragment>
      <p className="-mb-3 mt-12 font-medium text-gray-500 pl-3">MOST READ</p>
      <List>
        <Fragment>
          {articles.length ? (
            articles.map((ar) => (
              <li key={ar.id} className="w-full">
                {changeArticle && article?.id === ar.id ? (
                  <div className="flex items-center w-full justify-center">
                    <div className="mx-2 w-10/12">
                      <SelectAsync
                        className="mt-3"
                        option="editorial/all-not-most-read-articles"
                        setSelectedOption={(value) => {
                          setArticle(value);
                          ReplaceArticle(ar?.id, value.value);
                        }}
                      />
                    </div>
                    <div
                      className="ml-5 flex-shrink-0 text-red-500 hover:text-red-600 text-sm font-medium cursor-pointer"
                      onClick={() => setChangeArticle(null)}
                    >
                      Cancel{' '}
                    </div>
                  </div>
                ) : (
                  <div className="block hover:bg-gray-50 flex justify-between">
                    <div className="px-4 py-5 flex items-center sm:px-6 w-full">
                      <select
                        className="border-0 py-0 mr-4 focus:outline-0 focus:ring-transparent hover:bg-gray-50"
                        value={ar.most_read_article_order}
                        onChange={(e) =>
                          ChangeOrder(
                            ar.id,
                            GetArticleIdByOrder(e.target.value)
                          )
                        }
                      >
                        {[1, 2, 3, 4, 5].map((position) => (
                          <option key={position} value={position}>
                            Position {position}
                          </option>
                        ))}
                      </select>
                      <div
                        className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
                        onClick={() => {
                          setChangeArticle(true);
                          setArticle(ar);
                        }}
                      >
                        <div className="truncate">
                          <div className="text-sm">
                            <p className="font-medium text-indigo-600 truncate mb-1v">
                              {ar.title}
                            </p>
                            <div className="flex items-center text-sm text-gray-500">
                              <CalendarIcon
                                className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                                aria-hidden="true"
                              />
                              <p>
                                Published at{' '}
                                <time dateTime={'2020-01-07'}>
                                  {moment(ar.published_at).format(
                                    'DD-MM-YYYY HH:MM'
                                  )}
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer"
                        onClick={() => {
                          setChangeArticle(true);
                          setArticle(ar);
                        }}
                      >
                        {' '}
                        Change{' '}
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))
          ) : (
            <img className="img-not-found" src={NotFound} />
          )}
        </Fragment>
      </List>
    </Fragment>
  );
};

export default MostRead;
