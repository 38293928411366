import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Loader from 'react-js-loader';

import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';

import config from '../../config/api.config';

const Features = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [features, setFeatures] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultPage, setDefaultPage] = useState(false);
  const [header, setHeader] = useState('Add feature');
  const pages = [{ name: 'Features', href: '/features', current: true }];
  const [feature, setFeature] = useState({
    id: null,
    name: '',
    description: '',
    url_root: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });

  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const handleInputChange = (e) =>
    setFeature({ ...feature, [e.target.name]: e.target.value });

  const handleAddButton = () => {
    setOpenModal(true);
    setFeature({
      id: null,
      name: '',
      description: '',
      url_root: ''
    });

    setHeader('Add feature');
  };

  const AddFeature = async () => {
    setButtonClicked(true);
    await axios
      .post(`${config.apiUrl}/features`, {
        name: feature.name,
        url_root: feature.url_root,
        description: feature.description
      })
      .then((res) => {
        // console.log(res);
        setButtonClicked(false);
        setOpenModal(false);
        setAlert({
          header: 'Successfully added!',
          message: 'You have successfully added new feature.'
        });
        GetFeatures({ page: params.current_page });
      })
      .catch((err) => {
        setButtonClicked(false);
        console.error(err);
      });
  };

  const EditFeature = async () => {
    setButtonClicked(true);
    await axios
      .put(`${config.apiUrl}/features/${feature.id}`, {
        name: feature.name,
        url_root: feature.url_root,
        description: feature.description
      })
      .then((res) => {
        GetFeatures({ page: params.current_page });
        setButtonClicked(false);
        setOpenModal(false);
        setShowAlert(true);
        setAlert({
          header: 'Successfully edited!',
          message: 'You have successfully edited feature.'
        });
      })
      .catch((err) => {
        setButtonClicked(false);
        console.error(err);
      });
  };

  const GetFeatures = async (params) => {
    await axios
      .get(`${config.apiUrl}/features`, {
        params: {
          ...params
        }
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setFeatures(res.data.data);
        const { current_page, last_page, per_page, total } = res.data.meta;

        setParams({
          current_page,
          last_page,
          per_page,
          total
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const DeleteFeature = async () => {
    await axios
      .delete(`${config.apiUrl}/features/${feature.id}`)
      .then((res) => {
        console.log(res);
        setShowAlert(true);
        setAlert({
          header: 'Successfully deleted!',
          message: 'This feature is no longer available.'
        });

        if (features.length === 1) setDefaultPage(!defaultPage);

        GetFeatures({ page: features.length === 1 ? 1 : params.current_page });
      })
      .catch((err) => console.error(err));
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetFeatures({
      page: newPage
    });
  };

  useEffect(() => {
    setLoading(true);
    GetFeatures({ page: 1 });
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete feature"
        deleteFun={DeleteFeature}
      >
        Are you sure you want to delete this feature? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>

      <Modal
        open={openModal}
        setOpen={setOpenModal}
        header={header}
        buttonClicked={buttonClicked}
        save={feature.id ? EditFeature : AddFeature}
        valid={feature.name && feature.description && feature.url_root}
      >
        <Fragment>
          <FormInput
            label="Name"
            name="name"
            className="mb-3"
            value={feature.name}
            handleChange={handleInputChange}
          />
          <FormInput
            label="Url root"
            name="url_root"
            className="mb-3"
            value={feature.url_root}
            handleChange={handleInputChange}
          />
          <FormInput
            label="Description"
            name="description"
            className="mb-3"
            value={feature.description}
            handleChange={handleInputChange}
            style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
          />
        </Fragment>
      </Modal>

      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={handleAddButton}>
                  Create new feature
                </AddButton>
              </div>
            </div>

            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                <Fragment>
                  {features.map((feature) => (
                    <li key={feature.id}>
                      <div className="block hover:bg-gray-50 flex justify-between">
                        <div
                          className="px-4 py-5 flex items-center sm:px-6 w-full"
                          onClick={() => {
                            setOpenModal(true);
                            setFeature(feature);
                            setHeader('Edit feature');
                          }}
                        >
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {feature.name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                            {' '}
                            Edit{' '}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setFeature(feature);
                          }}
                          className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                        >
                          Delete
                        </div>
                      </div>
                    </li>
                  ))}
                </Fragment>
              </List>
              <Pagination
                params={params}
                getPageNumber={getPageNumber}
                defaultPage={defaultPage}
              />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Features;
