import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Redux from 'redux-thunk';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage
};

const middlewares = [Redux];
const persistedReducer = persistReducer(persistConfig, rootReducer);
// export const store = createStore(rootReducer, applyMiddleware(...middlewares));

// export default store;

export default () => {
  const store = createStore(persistedReducer, applyMiddleware(...middlewares));
  const persistor = persistStore(store);
  return { store, persistor };
};
