import React from 'react';
import './buttons.css';

const DeleteButton = ({ children, className, onClick }) => {
  return (
    <button
      type="button"
      className={`${className} button--delete inline-flex items-center px-5 py-1.5 border border-transparent text-sm font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
      onClick={onClick}
    >
      <span> {children} </span>
    </button>
  );
};

export default DeleteButton;
