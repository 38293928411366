import React, { Fragment, useEffect, useState } from 'react';
import Loader from 'react-js-loader';
import moment from 'moment';
import { CalendarIcon } from '@heroicons/react/solid';
import APIService from '../../APIService';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import SelectAsync from '../../components/multiselect/selectAsync';
import NotFound from '../../assets/images/notFound.png';
import ArticlesPosition from './ArticlesPosition';
import Covers from './covers';
import MostRead from './mostRead';

const Editorial = () => {
  const pages = [{ name: 'Editorial', href: '/editorial', current: true }];
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [changeArticle, setChangeArticle] = useState(null);
  const [category, setCategory] = useState(null);
  const [change, setChange] = useState(false);

  const GetEditorial = async () => await APIService.Get('editorial');

  const ReplaceCategoryArticle = async (id, article) => {
    const res = await APIService.Update(
      id,
      'editorial/editorial-category-main-article',
      { main_category_article_id: article }
    );
    GetCategories();
    setChange(true);
  };

  const GetCategories = async () =>
    await APIService.Get('categories', { allCategories: 1 });

  const promiseFunctions = async () => {
    await Promise.allSettled([GetEditorial(), GetCategories()])
      .then((res) => {
        setArticles(
          res[0].value.sort(function (a, b) {
            return a.main_article_order - b.main_article_order;
          })
        );
        setCategories(res[1].value.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    promiseFunctions();
  }, []);

  useEffect(() => {
    if (change) promiseFunctions();
    setChange(false);
  }, [change]);

  return (
    <Fragment>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <ArticlesPosition />
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <Covers articles={articles} setChange={setChange} />
              <MostRead />
              <p className="-mb-3 mt-10 font-medium text-gray-500 pl-3">
                CATEGORIES
              </p>
              <List>
                <Fragment>
                  {categories.length ? (
                    categories.map((ar) => (
                      <li key={ar?.title} className="w-full">
                        {changeArticle && category?.title === ar.title ? (
                          <div className="flex items-center w-full justify-center">
                            <div className="mx-2 w-10/12">
                              <SelectAsync
                                className="mt-3"
                                option="articles"
                                params={{ category: ar?.id }}
                                setSelectedOption={(value) => {
                                  setCategory(value);
                                  ReplaceCategoryArticle(ar?.id, value.value);
                                }}
                              />
                            </div>
                            <div
                              className="ml-5 flex-shrink-0 text-red-500 hover:text-red-600 text-sm font-medium cursor-pointer"
                              onClick={() => setChangeArticle(null)}
                            >
                              Cancel{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="block hover:bg-gray-50 flex justify-between">
                            <div
                              className="px-4 py-5 flex items-center sm:px-6 w-full"
                              onClick={() => {
                                setChangeArticle(true);
                                setCategory(ar);
                              }}
                            >
                              <p className="mr-7 font-medium  text-gray-600">
                                {ar.title}
                              </p>
                              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="truncate">
                                  <div className="text-sm">
                                    <p className="font-medium text-indigo-600 truncate mb-1v">
                                      {ar.main_category_article_id?.title}
                                    </p>
                                    {ar.main_category_article_id
                                      ?.created_at && (
                                      <div className="flex items-center text-sm text-gray-500">
                                        <CalendarIcon
                                          className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <p>
                                          Published at{' '}
                                          <time dateTime={'2020-01-07'}>
                                            {moment(
                                              ar.main_category_article_id
                                                ?.created_at
                                            ).format('DD-MM-YYYY HH:MM')}
                                          </time>
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                                {' '}
                                Change{' '}
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Editorial;
