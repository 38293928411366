import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Loader from 'react-js-loader';

import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';

import config from '../../config/api.config';

const Permissions = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultPage, setDefaultPage] = useState(false);
  const [header, setHeader] = useState('Add permission');
  const pages = [{ name: 'Permissions', href: '/permissions', current: true }];
  const [permission, setPermission] = useState({
    id: null,
    name: '',
    guard_name: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });

  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const handleInputChange = (e) =>
    setPermission({ ...permission, [e.target.name]: e.target.value });

  const handleAddButton = () => {
    setOpenModal(true);
    setPermission({
      id: null,
      name: '',
      guard_name: ''
    });

    setHeader('Add permission');
  };

  const GetPermissions = async () => {
    await axios
      .get(`${config.apiUrl}/permissions`, {
        params: {
          ...params
        }
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setPermissions(res.data.data);
        const { current_page, last_page, per_page, total } = res.data.meta;

        setParams({
          current_page,
          last_page,
          per_page,
          total
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const DeletePermission = async () => {
    await axios
      .delete(`${config.apiUrl}/permissions/${permission.id}`)
      .then((res) => {
        setShowAlert(true);
        setAlert({
          header: 'Successfully deleted!',
          message: 'This permission is no longer available.'
        });

        if (permissions.length === 1) setDefaultPage(!defaultPage);

        GetPermissions({
          page: permissions.length === 1 ? 1 : params.current_page
        });
      })
      .catch((err) => console.error(err));
  };

  const AddPermission = async () => {
    setButtonClicked(true);
    await axios
      .post(`${config.apiUrl}/permissions`, {
        name: permission.name
      })
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setAlert({
          header: 'Successfully added!',
          message: 'You have successfully added new permission.'
        });
        GetPermissions({ page: params.current_page });
      })
      .catch((err) => {
        setButtonClicked(false);
        console.error(err);
      });
  };

  const EditPermission = async () => {
    setButtonClicked(true);
    await axios
      .put(`${config.apiUrl}/permissions/${permission.id}`, {
        name: permission.name
      })
      .then((res) => {
        GetPermissions({ page: params.current_page });
        setButtonClicked(false);
        setOpenModal(false);
        setShowAlert(true);
        setAlert({
          header: 'Successfully edited!',
          message: 'You have successfully edited permission.'
        });
      })
      .catch((err) => {
        setButtonClicked(false);
        console.error(err);
      });
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetPermissions({
      page: newPage
    });
  };

  useEffect(() => {
    setLoading(true);
    GetPermissions({ page: 1 });
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete permission"
        deleteFun={DeletePermission}
      >
        Are you sure you want to delete this permission? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        header={header}
        buttonClicked={buttonClicked}
        save={permission.id ? EditPermission : AddPermission}
        valid={permission.name}
      >
        <FormInput
          label="Name"
          name="name"
          className="mb-3"
          value={permission.name}
          handleChange={handleInputChange}
        />
      </Modal>

      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={handleAddButton}>
                  Create new permission
                </AddButton>
              </div>
            </div>

            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                <Fragment>
                  {permissions.map((permission) => (
                    <li key={permission.id}>
                      <div className="block hover:bg-gray-50 flex justify-between">
                        <div
                          className="px-4 py-5 flex items-center sm:px-6 w-full"
                          onClick={() => {
                            setOpenModal(true);
                            setPermission(permission);
                            setHeader('Edit permission');
                          }}
                        >
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {permission.name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {permission.guard_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                            {' '}
                            Edit{' '}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setPermission(permission);
                          }}
                          className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                        >
                          Delete
                        </div>
                      </div>
                    </li>
                  ))}
                </Fragment>
              </List>
              <Pagination
                params={params}
                getPageNumber={getPageNumber}
                defaultPage={defaultPage}
              />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Permissions;
