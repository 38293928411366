import Articles from '../pages/Articles/Articles';
import AddArticle from '../pages/Articles/AddArticle';
import Tags from '../pages/Tags/Tags';
import Categories from '../pages/Categories/Categories';
import AddCategory from '../pages/Categories/AddCategory';
import Locations from '../pages/Locations/Locations';
import Dashboard from '../pages/Dashboard/Dashboard';
import CensoredWords from '../pages/CensoredWords/CensoredWords';
import SocialNetworks from '../pages/SocialNetworks/SocialNetworks';
import Users from '../pages/Users/Users';
import Media from '../pages/Media/Media';
import Authors from '../pages/Authors/Authors';
import AddAuthors from '../pages/Authors/AddAuthor';
import Editorial from '../pages/Editorial/Editorial';
import InfoGuide from '../pages/InfoGuide/InfoGuide';
import Informs from '../pages/Informs/Informs';
import InformsDetails from '../pages/Informs/SeeDetails';
import Profile from '../pages/Profile/Profile';
import Logs from '../pages/Logs/Logs';
import Comments from '../pages/Comments/Comments';
import Polls from '../pages/Polls/Polls';
import AddPoll from '../pages/Polls/AddPoll';

import Features from '../pages/CentralApp/Features';
import Menus from '../pages/CentralApp/Menus';
import Permissions from '../pages/CentralApp/Permissions';
import Roles from '../pages/CentralApp/Roles';
import Tenants from '../pages/CentralApp/Tenants';
import MenuItems from '../pages/CentralApp/MenuItems';

export const routes = [
  { path: '/dashboard', Component: Dashboard },
  { path: '/articles', Component: Articles },
  { path: '/add-article', Component: AddArticle },
  { path: '/edit-article/:id', Component: AddArticle },
  { path: '/tags', Component: Tags },
  { path: '/categories', Component: Categories },
  { path: '/add-category', Component: AddCategory },
  { path: '/edit-category/:id', Component: AddCategory },
  { path: '/locations', Component: Locations },
  { path: '/censored-words', Component: CensoredWords },
  { path: '/social-networks', Component: SocialNetworks },
  { path: '/users', Component: Users },
  { path: '/media', Component: Media },
  { path: '/features', Component: Features },
  { path: '/menus', Component: Menus },
  { path: '/permissions', Component: Permissions },
  { path: '/roles', Component: Roles },
  { path: '/tenants', Component: Tenants },
  { path: '/menu-items', Component: MenuItems },
  { path: '/authors', Component: Authors },
  { path: '/add-author', Component: AddAuthors },
  { path: '/edit-author/:id', Component: AddAuthors },
  { path: '/editorial', Component: Editorial },
  { path: '/info-guide', Component: InfoGuide },
  { path: '/informs', Component: Informs },
  { path: '/informs/details/:id', Component: InformsDetails },
  { path: '/profile', Component: Profile },
  { path: '/logs', Component: Logs },
  { path: '/article/:id/comments', Component: Comments },
  { path: '/polls', Component: Polls },
  { path: '/add-poll', Component: AddPoll },
  { path: '/edit-poll/:id', Component: AddPoll }
];
