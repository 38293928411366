import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContainerWrapper from '../../layout/ContainerWrapper/ContainerWrapper';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import config from '../../config/api.config';
import Loader from 'react-js-loader';

import APIService from '../../APIService';

import './informs.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const SeeDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const pages = [
    { name: 'Informs', href: '/informs', current: false },
    {
      name: 'Details',
      href: `/informs/details/${params.id}`,
      current: true
    }
  ];
  const [informs, setInforms] = useState();

  const GetDetails = async () => {
    setLoading(true);
    const result = await APIService.Get(`news-from-readers/${params.id}/show`);
    setInforms(result.data);
    setLoading(false);
  };

  function downloadImage(url, name) {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('An error sorry'));
  }

  useEffect(() => {
    GetDetails();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <div className="h-full flex items-center justify-center">
          <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
        </div>
      ) : (
        <Fragment>
          <div className="-ml-4 lg:-mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
            <Breadcrumb pages={pages} />
          </div>
          <div className="flex-1 flex">
            <ContainerWrapper className="p-12 pt-12">
              <h1 className="font-medium text-4xl">{informs?.title}</h1>
              <span className="mt-1 mb-6 text-lg font-medium text-gray-500">
                {informs?.full_name}
              </span>
              <div className="text-xl">{informs?.description}</div>
              <section
                className="mt-12 pb-16"
                aria-labelledby="gallery-heading"
              >
                {informs?.photos.length ? (
                  <ul role="list" className="grid gap-x-2 gap-y-6 grid-cols-5">
                    {informs?.photos.map((file, index) => (
                      <li
                        key={index}
                        className="relative cursor-pointer h-full p-2"
                      >
                        <div
                          style={{ height: '90%' }}
                          onClick={() => {
                            setInforms({
                              ...informs,
                              photos: [...informs?.photos].map((obj) => {
                                if (obj?.id === file?.id)
                                  return {
                                    ...obj,
                                    current: true
                                  };
                                else return { ...obj, current: false };
                              })
                            });
                          }}
                          className={classNames(
                            file?.current
                              ? 'ring-2 ring-offset-2 ring-indigo-500'
                              : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
                            'relative group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden informs-image'
                          )}
                        >
                          <div
                            style={{
                              backgroundColor: 'rgba(255,255,255,0.47)'
                            }}
                            className="absolute rounded-lg flex items-center justify-center"
                            onClick={() =>
                              downloadImage(
                                `${config.webUrl}/${file?.path}`,
                                file?.title
                              )
                            }
                          >
                            <span
                              style={{ backgroundColor: '#483b9fc9' }}
                              className="rounded p-2 shadow text-white uppercase text-sm"
                            >
                              Download
                            </span>
                          </div>
                          <img
                            src={`${config.webUrl}/${file?.path}`}
                            alt=""
                            className={
                              'object-cover pointer-events-none h-full mx-auto'
                            }
                          />
                        </div>
                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                          {file?.title}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </section>
            </ContainerWrapper>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SeeDetails;
