export class CustomH3 {
  constructor({ data }) {
    this.data = data;
  }

  // static get toolbox() {
  //     return {
  //       title: 'H3',
  //       icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.8em" height="1.8em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M14.11 14.218c.355.287.75.523 1.17.7c.434.18.9.273 1.37.27c.484.017.965-.086 1.4-.3a.919.919 0 0 0 .55-.84a1.146 1.146 0 0 0-.15-.58c-.123-.19-.3-.34-.51-.43a3.66 3.66 0 0 0-1-.27a9.786 9.786 0 0 0-1.52-.1v-1.57a4.717 4.717 0 0 0 2.17-.36a1.06 1.06 0 0 0 .63-.93a.903.903 0 0 0-.38-.81c-.34-.203-.734-.3-1.13-.28a2.836 2.836 0 0 0-1.14.28a5.6 5.6 0 0 0-1.06.62l-1.22-1.39c.5-.377 1.053-.68 1.64-.9a5.354 5.354 0 0 1 1.9-.33c.525-.007 1.05.05 1.56.17c.43.1.84.277 1.21.52c.325.21.595.495.79.83c.19.342.287.73.28 1.12c.01.48-.177.943-.52 1.28a3.88 3.88 0 0 1-1.46.86v.06a3.88 3.88 0 0 1 1.65.83c.437.382.68.94.66 1.52c.005.42-.113.835-.34 1.19c-.23.357-.538.657-.9.88c-.408.253-.853.44-1.32.55a6.39 6.39 0 0 1-1.57.19a6.395 6.395 0 0 1-2.31-.37a4.29 4.29 0 0 1-1.57-1l1.12-1.41zM9 11H5V7H3v10h2v-4h4v4h2V7H9v4z" fill="currentColor"/></svg>'
  //     };
  //   }

  render() {
    const h3 = document.createElement('input');
    h3.placeholder = this.data?.placeholder ?? '';
    h3.style.fontSize = '1.125em';
    h3.style.padding = '0.7em 0';
    h3.style.border = 'none';
    h3.style.outline = 'none';
    h3.style.width = '100%';
    h3.value = this.data && this.data.text ? this.data.text : '';

    return h3;
  }

  save(blockContent) {
    return {
      text: blockContent.value
    };
  }
}
