import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import './accordion.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: '#3C2F80'
  }
}));

const AccordionCustom = ({
  header,
  expanded,
  className,
  expandIcon = false,
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Accordion className={className} expanded={expanded} {...otherProps}>
      <AccordionSummary
        expandIcon={expandIcon ? <ExpandMoreIcon /> : null}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails className="flex-col">{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionCustom;
