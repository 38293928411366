import React from 'react';
import { CircularProgress } from '@material-ui/core';

const SubmitButton = ({
  onClick,
  className,
  valid = true,
  buttonClicked = false,
  children,
  ...otherProps
}) => {
  return (
    <div className={className}>
      {valid && !buttonClicked ? (
        <button
          onClick={buttonClicked ? null : onClick}
          variant="contained"
          className=" button--add w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-9 py-1.5 bg-indigo-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          //   className={classes.root}
          {...otherProps}
        >
          {children}
        </button>
      ) : (
        <button
          variant="contained"
          disabled
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-9 py-1.5 bg-gray-200 text-base font-medium text-gray-400 focus:outline-none cursor-not-allowed"
          {...otherProps}
        >
          {buttonClicked ? (
            <CircularProgress disableShrink color="inherit" size="1.2rem" />
          ) : (
            children
          )}
        </button>
      )}
    </div>
  );
};

export default SubmitButton;
