import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../components/functions/functions';
import config from '../../config/api.config';
import axios from 'axios';
import { setUser } from '../../redux/user/action';
import { useSelector, useDispatch} from 'react-redux'

import auth from '../../auth';

const ProfileDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({user}) => user.user)

  const Logout = (e) => {
    e.preventDefault();
    auth.logout(() => {
      history.push('/login');
    });
  };

  const GetData = async () => {
    await axios
      .get(`${config.apiUrlBuka}/users/user/me`)
      .then((res) =>{dispatch(setUser(res.data.data))})
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    GetData();
  }, []);

  const userNavigation = [
    {
      name: 'Your Profile',
      href: '#',
      onClick: () => history.push('/profile')
    },
    { name: 'Sign out', href: '#', onClick: Logout }
  ];

  return (
    <Menu as="div" className="relative flex-shrink-0">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-indigo-600-hover">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full object-cover"
                src={user && `${config.webUrl}/${user.image.path}`}
                alt=""
              />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      onClick={item.onClick}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ProfileDropdown;
