import React, { Fragment, useState, useEffect } from 'react';
import Loader from 'react-js-loader';
import { useHistory } from 'react-router-dom';
import APIService from '../../APIService';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import NotFound from '../../assets/images/notFound.png';
import ProfileImg from '../../assets/images/profile.png';

const Authors = () => {
  const history = useHistory();

  const [authors, setAuthors] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const pages = [{ name: 'Authors', href: '/authors', current: true }];
  const [author, setAuthor] = useState({
    id: null
  });
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });

  const GetAuthors = async (params) => {
    const result = await APIService.Get('authors', { ...params });
    setLoading(false);
    setAuthors(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  const DeleteAuthor = async () => {
    const result = await APIService.Delete(author.id, 'authors');
    setAlert({
      header: 'Successfully deleted!',
      message: 'This author is no longer available.'
    });
    setShowAlert(true);
    GetAuthors({ page: params.current_page });
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetAuthors({
      page: newPage
    });
  };

  useEffect(() => {
    setLoading(true);
    GetAuthors({ page: 1 });
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete author"
        deleteFun={DeleteAuthor}
      >
        Are you sure you want to delete this author? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>

      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={() => history.push('/add-author')}>
                  Create new author
                </AddButton>
              </div>
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                {authors.length ? (
                  authors.map((author) => (
                    <li key={author.id}>
                      <div className="block hover:bg-gray-50 flex justify-between">
                        <div
                          className="px-4 py-5 flex items-center sm:px-6 w-full  justify-between"
                          onClick={() => {
                            setAuthor(author);
                            history.push({
                              pathname: `/edit-author/${author.id}`,
                              state: {
                                authorId: author.id
                              }
                            });
                          }}
                        >
                          <div className="min-w-0 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex items-center text-sm">
                                <div className="flex-shrink-0 h-10 w-10 mr-5">
                                  <img
                                    className="h-10 w-10 rounded-full object-cover"
                                    src={
                                      author.photo
                                        ? `https://6yka.infodesk.io/${author.photo?.path}`
                                        : ProfileImg
                                    }
                                    alt=""
                                  />
                                </div>
                                <p className="font-medium text-gray-600 truncate">
                                  {author.full_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="text-sm">
                            Number of created articles:{' '}
                            <span className="font-medium">
                              {author.num_of_created_articles}
                            </span>
                          </p>
                          <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                            {' '}
                            Edit{' '}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setAuthor(author);
                          }}
                          className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                        >
                          Delete
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <img className="img-not-found" src={NotFound} />
                )}
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Authors;
