import React, { Fragment, useEffect, useState } from 'react';
import Loader from 'react-js-loader';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import NotFound from '../../assets/images/notFound.png';
import Error from '../../components/alerts/error';
import APIService from '../../APIService';
import { useHistory } from 'react-router-dom';

const Polls = () => {
  const history = useHistory();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(false);
  const pages = [{ name: 'Polls', href: '/polls', current: true }];
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [poll, setPoll] = useState({
    id: null,
    question: '',
    description: ''
  });

  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const GetPolls = async (params) => {
    const result = await APIService.Get('polls', { ...params });
    setLoading(false);
    setPolls(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetPolls({
      page: newPage
    });
  };

  const DeletePoll = async () => {
    try {
      const result = await APIService.Delete(poll.id, 'polls');
      setAlert({
        header: 'Successfully deleted!',
        message: 'This poll is no longer available.'
      });
      setShowAlert(true);
      GetPolls({ page: params.current_page });
    } catch (err) {
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
      setShowError(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    GetPolls({ page: 1 });
  }, []);
  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete poll"
        deleteFun={DeletePoll}
      >
        Are you sure you want to delete this poll? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>

      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={() => history.push('/add-poll')}>
                  Create new poll
                </AddButton>
              </div>
            </div>

            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                <Fragment>
                  {polls.length ? (
                    polls.map((poll) => (
                      <li key={poll.id}>
                        <div className="block hover:bg-gray-50 flex justify-between">
                          <div
                            className="px-4 py-5 flex items-center sm:px-6 w-full"
                            onClick={() =>
                              history.push(`/edit-poll/${poll.id}`)
                            }
                          >
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    {poll.question}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                              {' '}
                              Edit{' '}
                            </div>
                          </div>
                          <div
                            onClick={(_) => {
                              setOpenDeleteModal(true);
                              setPoll({ ...poll, id: poll.id });
                            }}
                            className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Polls;
