import React, { Fragment, useState, useEffect } from 'react';
import Loader from 'react-js-loader';
import moment from 'moment';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import NotFound from '../../assets/images/notFound.png';
import List from '../../components/stackedList/stackedList';
import { CalendarIcon } from '@heroicons/react/solid';
import Covers from '../Editorial/covers';
import APIService from '../../APIService';
import config from '../../config/api.config';

const Dashboard = () => {
  const pages = [];
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const [articles, setArticles] = useState([]);
  const [change, setChange] = useState(false);

  const GetEditorial = async () => {
    const result = await APIService.Get('editorial');
    setArticles(
      result.filter(r => r.main_article_order).sort(function (a, b) {
        return a.main_article_order - b.main_article_order;
      })
    );
    setLoading(false);
  };
  const GetStatistics = async () => {
    const result = await APIService.Get('dashboard');
    setStatistics(result);
  };

  useEffect(() => {
    setLoading(true);
    GetStatistics();
    GetEditorial();
  }, []);

  useEffect(() => {
    if (change) GetEditorial();
    setChange(false);
  }, [change]);

  return (
    <Fragment>
      <Breadcrumb pages={pages} />
      <div className="flex-1 flex flex-col">
        <div className="lg:flex justify-center items-center w-full">
          <div className="mb-5 bg-white rounded-lg shadow lg:w-96 h-48 lg:mr-10 flex flex-col justify-center items-center lg:mb-0">
            <p className="text-lg text-gray-400">
              Number of articles you have created today
            </p>
            <span className="bg-indigo-600-color font-medium text-5xl mt-4">
              {statistics?.todaysArticleNumberMadeByAuthUser}
            </span>
          </div>

          <div className="bg-white rounded-lg shadow lg:w-96 h-48 flex flex-col justify-center items-center">
            <p className="text-lg text-gray-400">
              Number of articles created today
            </p>
            <span className="bg-indigo-600-color font-medium text-5xl mt-4">
              {statistics?.todaysArticlesNumber}
            </span>
          </div>
        </div>
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <>
            <Fragment>
              <Covers articles={articles} setChange={setChange} />
            </Fragment>
            <Fragment>
              <p className="flex justify-between pt-3.5 px-4 font-medium text-gray-500 mt-5">
                LATEST ARTICLES
              </p>
              <List>
                <Fragment>
                  {statistics?.newestArticles.length ? (
                    statistics?.newestArticles.map((article) => (
                      <li key={article.id}>
                        <div className="block cursor-pointer hover:bg-gray-50">
                          <div className="px-4 py-4 flex items-center sm:px-6 relative">
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate flex align-center">
                                <div className="flex-shrink-0 mr-5">
                                  <img
                                    className="h-12 w-12 rounded-full object-cover"
                                    alt=""
                                    src={
                                      article.thumbnail_image &&
                                      (!JSON.parse(
                                        article.thumbnail_image
                                      ).data.files[0].url.includes(
                                        config.webUrl
                                      )
                                        ? `${config.webUrl}/${
                                            JSON.parse(article.thumbnail_image)
                                              .data.files[0].url
                                          }`
                                        : JSON.parse(article.thumbnail_image)
                                            .data.files[0].url)
                                    }
                                  />
                                </div>
                                <div>
                                  <div className="flex text-sm">
                                    <p className="font-medium text-indigo-600 truncate">
                                      {article.title}
                                    </p>
                                  </div>
                                  <div className="mt-2 flex">
                                    {article.published_at ? (
                                      <div className="flex items-center text-sm text-gray-500">
                                        <CalendarIcon
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <p>
                                          {moment(article.published_at).isAfter(
                                            new Date()
                                          )
                                            ? 'Coming up '
                                            : 'Published at '}
                                          <time dateTime={'2020-01-07'}>
                                            {moment(
                                              article.published_at
                                            ).format('DD-MM-YYYY HH:MM')}
                                          </time>
                                        </p>
                                      </div>
                                    ) : (
                                      <p className="flex items-center text-sm text-gray-500">
                                        Not published yet
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
            </Fragment>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default Dashboard;
