import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import ReactHtmlParser from 'react-html-parser';
import EditorJs from 'react-editor-js';

import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import Embed from '@editorjs/embed';
import RawTool from '@editorjs/raw';
import { useMediaQuery } from 'react-responsive';

import ContainerWrapper from '../../layout/ContainerWrapper/ContainerWrapper';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import MultiselectCreate from '../../components/multiselect/multiselectCreate';
import MultiselectAsync from '../../components/multiselect/multiselectAsync';
import Select from '../../components/multiselect/select';
import Accordion from '../../components/accordion/accordion';
import Checkbox from '../../components/checkbox/checkbox';
import Input from '../../components/formInput/formInput';
import DatetimePicker from '../../components/datetimePicker/datetimePicker';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Success from '../../components/alerts/success';
import Error from '../../components/alerts/error';
import MediaGallery from '../../components/mediaGallery/mediaGallery';
import Modal from '../../components/modal/modal';
import DropdownButton from '../../components/buttons/DropdownButton';
import Button from '../../components/buttons/Button';
import Seo from '../../components/seo/seoAccordion';
import CustomModal from '../../components/modal/customModal';
import SubmitButton from '../../components/buttons/SubmitButton';
import AddButton from '../../components/buttons/AddButton';

import { CustomH2 } from '../../components/editor/customH2';
import { CustomH3 } from '../../components/editor/customH3';
import { CustomH4 } from '../../components/editor/customH4';
import { CustomImageTool } from '../../components/editor/customImageTool';
import { CustomGalleryTool } from '../../components/editor/customGalleryTool';
import { CustomArticle } from '../../components/editor/customArticle';
import { CustomPoll } from '../../components/editor/customPoll';

import '../../assets/css/unreset.scss';
import config from '../../config/api.config';
import auth from '../../auth';
import axios from 'axios';
import LocalStorageService from '../../LocalStorageService';

import { ReactComponent as Upload } from '../../assets/images/uploadImage.svg';
import APIService from '../../APIService';
import './article.css';
const Header = require('@editorjs/header');

const textToHTML = (str) => {
  return <div className="article__video-preview">{ReactHtmlParser(str)}</div>;
};

const AddArticle = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const instanceRef = useRef(null);
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [valid, setValid] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openModalVideo, setOpenModalVideo] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [publishedDate, setPublishedDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState();
  const [startsAt, setStartsAt] = useState();
  const [endsAt, setEndsAt] = useState();
  const [openGallery, setOpenGallery] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [allowMainImage, setAllowMainImage] = useState(false);
  const [mainImage, setMainImage] = useState(null);
  const [mainGallery, setMainGallery] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState([]);
  const [wrapperId, setWrapperId] = useState(-1);
  const [mediaType, setMediaType] = useState('image');
  const [mediaTypeTemp, setMediaTypeTemp] = useState('image');
  const [video, setVideo] = useState('');
  const [renderEditor, setRenderEditor] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showAside, setShowAside] = useState(false);

  const [article, setArticle] = useState({
    id: null,
    title: '',
    pretitle: '',
    subtitle: '',
    content: '',
    excerpt: '',
    article_type: null,
    template: null,
    categories: null,
    tags: [],
    status: null,
    locations: null,
    read_time: 0,
    slug: '',
    canonical_url: '',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    authors: [],
    related_articles: null,
    images: [],
    being_edited_by: null
  });

  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const pages = [
    {
      name: article.article_type?.value === 2 ? 'Info Guide' : 'Articles',
      href: article.article_type?.value === 2 ? '/info-guide' : '/articles',
      current: false
    },
    {
      name: location.pathname.includes('edit-article')
        ? 'Edit article'
        : 'Add article',
      href: location.pathname.includes('edit-article')
        ? `/edit-article/${params.id}`
        : '/add-article',
      current: true
    }
  ];

  const GetArticleBySlug = (url) =>
    APIService.Get(`articles/article-slug/${url}`);
  const GetPolls = (params) => APIService.Get(`polls`, { ...params });

  const editorTools = {
    customH2: {
      class: CustomH2
    },
    customH3: {
      class: CustomH3
    },
    customH4: {
      class: CustomH4
    },
    customArticle: {
      class: CustomArticle,
      config: {
        getArticle: GetArticleBySlug
      }
    },
    customImageTool: {
      class: CustomImageTool,
      config: {
        setState: setOpenGallery,
        setMainImage: setAllowMainImage,
        setWrapperId: setWrapperId,
        image: mainImage,
        setGallery: setGallery
      }
    },
    customGalleryTool: {
      class: CustomGalleryTool
    },
    customPoll: {
      class: CustomPoll,
      config: {
        getPolls: GetPolls
      }
    },
    raw: RawTool,
    header: {
      class: Header,
      config: {
        placeholder: 'Header'
      }
    },
    list: {
      class: List
    },
    paragraph: {
      class: Paragraph,
      config: {
        placeholder: 'Content*'
      }
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true
        }
      }
    },
    quote: Quote
  };

  const [dynamicAttributes, setDynamicAttributes] = useState([
    {
      checked: false,
      show: true,
      name: 'ad_free',
      label: 'Ad free'
    },
    {
      checked: false,
      show: true,
      name: 'proofread',
      label: 'Proofread'
    },
    {
      checked: true,
      show: true,
      name: 'comments',
      label: 'Comments'
    },
    {
      checked: false,
      show: false,
      name: 'main_article',
      label: 'Main article'
    },
    {
      checked: false,
      show: false,
      name: 'most_read',
      label: 'Most read'
    }
  ]);

  const [templates, setTemplates] = useState([]);
  const [articleTypes, setArticleTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const handleClickPopover = (event) => setAnchorEl(event.currentTarget);
  const handleClosePopover = () => setAnchorEl(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleChange = (event) => {
    setDynamicAttributes(
      [...dynamicAttributes].map((att) => {
        if (att.name === event.target.name) {
          return {
            ...att,
            checked: event.target.checked
          };
        } else return att;
      })
    );
  };

  const CreateTag = async (value) => {
    setIsAddingInProgress(true);
    const result = await APIService.Insert('tags', {
      name: value,
      seo_title: value,
      seo_description: '',
      seo_keywords: ''
    });
    setArticle({
      ...article,
      tags: [
        ...article.tags,
        { value: result.data.id, label: result.data.name }
      ]
    });
    setIsAddingInProgress(false);
  };

  const CreateAuthor = async (value) => {
    setIsAddingInProgress(true);
    const result = await APIService.Insert('authors', {
      full_name: value,
      excerpt: value,
      columnist: true,
      social_links: {},
      published_at: new Date(),
      template_id: 1,
      seo_title: value,
      seo_description: '',
      seo_keywords: ''
    });
    setArticle({
      ...article,
      authors: [
        ...article.authors,
        { value: result.data.id, label: result.data.full_name }
      ]
    });
  };

  const handleState = (e) => {
    setArticle({ ...article, [e.target.name]: e.target.value });
  };
  const renderDynamicAttributes = () =>
    dynamicAttributes.map(
      (att) =>
        att.show && (
          <Checkbox
            key={att.name}
            checked={att.checked}
            name={att.name}
            label={att.label}
            handleChange={handleChange}
          />
        )
    );

  const DeleteArticle = async () => {
    const result = APIService.Delete(article.id, 'articles');
    setIsDeleted(true);
    setAlert({
      header: 'Successfully deleted!',
      message: 'This article is no longer available.'
    });
    setShowAlert(true);
    setTimeout((_) => history.push('/articles'), 1000);
  };

  useEffect(() => {
    if (mainImage) setAllowMainImage(false);
  }, [mainImage]);

  useEffect(() => {
    if (!openGallery && parseInt(wrapperId) !== -1) {
      CustomGalleryTool.render(gallery, wrapperId);
      setThumbnail(false);
    }
  }, [openGallery]);

  useEffect(() => {
    if (parseInt(wrapperId) !== -1 && params.id) {
      CustomGalleryTool.render(gallery, wrapperId);
      setThumbnail(false);
    }
  }, [gallery]);

  const AddArticle = async (publish = false) => {
    await instanceRef.current
      .save()
      .then(async (outputData) => {
        await axios
          .post(`${config.apiUrlBuka}/articles`, {
            title: article.title,
            subtitle: article.subtitle,
            pretitle: article.pretitle,
            excerpt: article.excerpt,
            content: outputData,
            read_time: article.read_time,
            settings: Object.fromEntries(
              dynamicAttributes.map((att) => [att.name, att.checked])
            ),
            published_at: publish
              ? moment(new Date()).format()
              : moment(publishedDate).format(),
            //  published_at: publish ?  new Date() : publishedDate,
            expires_at: expiredDate,
            starts_at: startsAt,
            ends_at: endsAt,
            status_id: article.status?.value,
            article_type_id: article.article_type?.value,
            template_id: article.template?.value,
            categories: [
              Array.isArray(article.categories)
                ? article.categories[0]?.value
                : article.categories?.value
            ],
            tags: article.tags.length
              ? article.tags?.map((tag) => tag?.value)
              : null,
            relatedArticles:
              article.related_articles?.map((article) => article?.value) ?? [],
            authors: article.authors?.map((tag) => tag?.value),
            locations: article.locations?.map((tag) => tag?.value),
            polls: [],
            seo_title: article.seo_title,
            seo_description: article.seo_description,
            seo_keywords: article.seo_keywords,
            thumbnail_image_url: thumbnailImage?.source,
            thumbnail_image: {
              type: 'media',
              data: {
                type: 'image',
                files: [
                  {
                    id: thumbnailImage[0]?.id,
                    url: thumbnailImage[0]?.source,
                    author: thumbnailImage[0]?.author_id?.label,
                    description: thumbnailImage[0]?.description,
                    focal_point: thumbnailImage[0]?.focal_point
                  }
                ]
              }
            },
            main_multimedia: {
              type: 'media',
              data: {
                type: mediaType,
                files:
                  mediaType === 'image'
                    ? [
                        {
                          id: mainImage?.id,
                          url: mainImage?.source,
                          author: mainImage?.author_id?.label,
                          description: mainImage?.description,
                          focal_point: mainImage?.focal_point
                        }
                      ]
                    : mediaType === 'video'
                    ? [{ url: video }]
                    : mainGallery.map((img) => {
                        return {
                          id: img?.id,
                          url: img?.source,
                          author: img?.author_id?.label,
                          description: img?.description,
                          focal_point: img?.focal_point
                        };
                      })
              }
            }
          })
          .then((res) => {
            setArticle({
              ...article,
              slug: res.data.data.slug,
              id: res.data.data.id,
              canonical_url: res.data.data.seo
                ? JSON.parse(res.data.data.seo.canonical_url).data
                : null,
              categories: res.data.data.categories?.map((c) => {
                return { value: c.id, label: c.title };
              })
            });
            setShowAlert(true);
            setAlert({
              header: 'Successfully added!',
              message: 'You have successfully added new article.'
            });
            publish && history.push('/articles');
          })
          .catch((err) => {
            setShowError(true);
            console.error(err);
            Object.entries(err.response.data.errors).forEach(([key, value]) =>
              setError([...value])
            );
          });
      })
      .catch((error) => {
        console.log('Saving failed: ', error);
      });
  };

  const EditArticle = async (publish = false) => {
    await instanceRef.current
      .save()
      .then(async (outputData) => {
        try {
          const result = await APIService.Update(
            params.id ?? article.id,
            'articles',
            {
              title: article.title,
              subtitle: article.subtitle,
              pretitle: article.pretitle,
              excerpt: article.excerpt,
              content: outputData,
              read_time: article.read_time,
              slug: article.slug,
              settings: Object.fromEntries(
                dynamicAttributes.map((att) => [att.name, att.checked])
              ),
              published_at: publish
                ? moment(new Date()).format()
                : moment(publishedDate).format(),
              expires_at: expiredDate,
              starts_at: startsAt,
              ends_at: endsAt,
              ...(!publishedDate && {
                published_at: publish
                  ? moment(new Date()).format()
                  : moment(publishedDate).format()
              }),
              // ...(!publishedDate && {published_at: publish ?  new Date() : publishedDate}),
              status_id: article.status?.value,
              article_type_id: article.article_type?.value,
              template_id: article.template?.value,
              categories: [
                Array.isArray(article.categories)
                  ? article.categories[0]?.value
                  : article.categories?.value
              ],
              tags: article.tags.length
                ? article.tags?.map((tag) => tag?.value)
                : null,
              relatedArticles:
                article.related_articles?.map((article) => article?.value) ??
                [],
              authors: article.authors?.map((tag) => tag?.value),
              locations: article.locations?.map((tag) => tag?.value),
              polls: [],
              seo_title: article.seo_title,
              seo_description: article.seo_description,
              seo_keywords: article.seo_keywords,
              thumbnail_image_url: thumbnailImage?.source,
              thumbnail_image: {
                type: 'media',
                data: {
                  type: 'image',
                  files: [
                    {
                      id: thumbnailImage[0]?.id,
                      url: thumbnailImage[0]?.source,
                      author: thumbnailImage[0]?.author_id?.label,
                      description: thumbnailImage[0]?.description,
                      focal_point: thumbnailImage[0]?.focal_point
                    }
                  ]
                }
              },
              main_multimedia: {
                id: 'kTDMIAwRid',
                type: 'media',
                data: {
                  type: mediaType,
                  files:
                    mediaType === 'image'
                      ? [
                          {
                            id: mainImage?.id,
                            url: mainImage?.source,
                            author: mainImage?.author_id?.label,
                            description: mainImage?.description,
                            focal_point: mainImage?.focal_point
                          }
                        ]
                      : mediaType === 'video'
                      ? [{ url: video }]
                      : mainGallery.map((img) => {
                          return {
                            id: img?.id,
                            url: img?.source,
                            author: img?.author_id?.label,
                            description: img?.description,
                            focal_point: img?.focal_point
                          };
                        })
                }
              }
            }
          );
          setShowAlert(true);
          setAlert({
            header: 'Successfully edited!',
            message: 'You have successfully edited new article.'
          });
          publish && history.push('/articles');
        } catch (err) {
          setShowError(true);
          console.error(err);
          Object.entries(err.response.data.errors).forEach(([key, value]) =>
            setError([...value])
          );
        }
      })
      .catch((error) => {
        console.log('Saving failed: ', error);
      });
  };
  const GetArticleCreate = async () => {
    const result = await APIService.Get(`articles/create`);
    setArticleTypes(
      result.articleTypes.map((a) => {
        return {
          value: a.id,
          label: a.title,
          allowed_settings: a.allowed_settings
        };
      })
    );
    setCategories(
      result.categories.map((cat) => {
        return { value: cat.id, label: cat.title };
      })
    );
    setTemplates(
      result.templates.map((t) => {
        return {
          value: t.id,
          label: t.title
        };
      })
    );
    setStatuses(
      result.statuses
        .map((s) => {
          return {
            value: s.id,
            label: s.name
          };
        })
        .filter((s) =>
          auth.isBloger() ? s.label === 'Draft' || s.label === 'Review' : s
        )
    );
  };

  const GetArticleById = async (id) => {
    const result = await APIService.Get(`articles/${id}/edit`);
    const ar = result.data;

    setArticle({
      ...article,
      id: ar.id,
      title: ar.title,
      pretitle: ar.pretitle,
      subtitle: ar.subtitle,
      being_edited_by: ar.being_edited_by,
      content: JSON.parse(ar.content),
      excerpt: ar.excerpt,
      article_type: {
        value: ar.article_type?.id,
        label: ar.article_type?.title,
        allowed_settings: JSON.parse(ar?.article_type?.allowed_settings)
      },
      template: { value: ar.template?.id, label: ar.template?.title },
      categories: ar.categories?.map((c) => {
        return { value: c.id, label: c.title };
      }),
      tags: ar.tags?.map((c) => {
        return { value: c.id, label: c.name };
      }),
      status: { value: ar.status?.id, label: ar.status?.name },
      // locations: null,
      read_time: ar.read_time,
      slug: ar.slug,
      canonical_url: ar.seo ? JSON.parse(ar.seo.canonical_url).data : null,
      seo_title: ar.seo?.title,
      seo_description: ar.seo?.description,
      seo_keywords: ar.seo?.keywords ?? '',
      authors: ar.authors?.map((a) => {
        return { value: a.id, label: a.full_name };
      }),
      related_articles: ar.relatedArticles?.map((a) => {
        return { value: a.id, label: a.title };
      })
    });

    const main_multimedia = JSON.parse(ar.main_multimedia);
    setMediaType(main_multimedia.data.type);

    if (main_multimedia.data.type === 'image')
      setMainImage({
        ...main_multimedia.data.files[0],
        source: !main_multimedia.data.files[0].url.includes(config.webUrl)
          ? `${config.webUrl}/${main_multimedia.data.files[0].url}`
          : main_multimedia.data.files[0].url
      });
    else if (main_multimedia.data.type === 'video')
      setVideo(main_multimedia.data.files[0].url);

    setDynamicAttributes(
      [...dynamicAttributes].map((att) => {
        if (
          Object.entries(JSON.parse(ar.settings)).find(
            ([key, value]) => key === att.name && value
          )
        ) {
          return {
            ...att,
            checked: true
          };
        } else return att;
      })
    );

    const thumbnail_image = JSON.parse(ar.thumbnail_image);
    setThumbnailImage([
      {
        ...thumbnail_image.data.files[0],
        source: !thumbnail_image.data.files[0].url.includes(config.webUrl)
          ? `${config.webUrl}/${thumbnail_image.data.files[0].url}`
          : thumbnail_image.data.files[0].url
      }
    ]);

    setPublishedDate(ar.published_at ? new Date(ar.published_at) : null);
    setExpiredDate(ar.expires_at ? new Date(ar.expires_at) : null);
    setRenderEditor(true);

    if (
      ar.being_edited_by &&
      auth.getId() != ar.being_edited_by.id &&
      !location.state?.redirect
    )
      setEditModal(true);
  };

  const ChangeStatus = async () => {
    const res = await APIService.Get(`articles/${article?.id}/change-status`);
    setShowAlert(true);
    setAlert({
      header: 'Successfully unpublished!',
      message: 'You have successfully unpublished article.'
    });
  };

  useEffect(() => {
    if (!params.id)
      setArticle({
        ...article,
        article_type: articleTypes.find((a) => a.label === 'Standard')
      });
  }, [articleTypes]);
  useEffect(() => {
    if (!params.id)
      setArticle({
        ...article,
        template: templates.find((a) => a.label === 'Standard')
      });
  }, [templates]);
  useEffect(() => {
    if (!params.id)
      setArticle({
        ...article,
        status: statuses.find((a) => a.label === 'Draft')
      });
  }, [statuses]);

  useEffect(() => {
    if (
      article.title &&
      article.title.length < 200 &&
      article.status &&
      article.article_type &&
      article.template &&
      article.categories &&
      article.seo_title &&
      thumbnailImage.length &&
      (mainImage || video || mainGallery.length)
    )
      setValid(true);
    else setValid(false);
  }, [
    article.title,
    article.status,
    article.article_type,
    article.template,
    article.categories,
    article.seo_title,
    thumbnailImage,
    mainImage,
    video,
    mainGallery
  ]);

  useEffect(() => {
    GetArticleCreate();
    if (params.id) GetArticleById(params.id);
    else setRenderEditor(true);

    if (params.id && !isDeleted) {
      return async () => {
        const res = await APIService.Update(
          `${params.id}/edited-by`,
          'articles',
          { being_edited_by: null }
        );
      };
    }
  }, []);

  useEffect(() => {
    setThumbnailImage(mainImage ? [mainImage] : []);
  }, [mainImage]);

  return (
    <Fragment>
      <CustomModal
        buttonClicked={!auth.isAdmin()}
        open={editModal}
        setOpen={setEditModal}
      >
        <p className="text-xl font-medium text-gray-500">
          This article is edited by{' '}
          <span className="bg-indigo-600-color">
            {article?.being_edited_by?.first_name}{' '}
            {article?.being_edited_by?.last_name}
          </span>
          .{' '}
          {auth.isAdmin()
            ? 'Do you still want to edit?'
            : "You don't have access right now."}
        </p>
        {auth.isAdmin() ? (
          <div className="flex justify-center mt-8">
            <button
              type="button"
              className="w-full mr-4 inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-6 py-1.5 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => history.push('/articles')}
            >
              No
            </button>
            <SubmitButton valid={true} onClick={() => setEditModal(false)}>
              Yes{' '}
            </SubmitButton>
          </div>
        ) : null}
      </CustomModal>

      <Modal
        open={openModalVideo}
        setOpen={setOpenModalVideo}
        cancel={() => setVideo('')}
        header={''}
        save={() => setOpenModalVideo(false)}
        valid={true}
      >
        <Fragment>
          <Input
            label="Paste embed code of video"
            name="video"
            type="url"
            value={video}
            handleChange={(e) => setVideo(e.target.value)}
          />
        </Fragment>
      </Modal>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        header="Delete article"
        deleteFun={DeleteArticle}
      >
        Are you sure you want to delete this article? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <div className="-ml-4 lg:-mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
        <Breadcrumb pages={pages} />
        <AddButton
          className="lg:hidden"
          onClick={() => setShowAside(!showAside)}
        />
      </div>
      <div className="flex-1 flex">
        <ContainerWrapper>
          <div id="codex-editor" className="unreset">
            <div style={{ padding: '0em 0.65em' }}>
              <input
                name="pretitle"
                value={article.pretitle}
                onChange={handleState}
                placeholder="Pretitle"
                className="editorInput editorInput__h3"
                autoComplete="off"
              />
              <input
                name="title"
                value={article.title}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    title: e.target.value,
                    seo_title: e.target.value
                  });
                }}
                placeholder="Title*"
                className="editorInput editorInput__h2"
                autoComplete="off"
              />
              <input
                name="subtitle"
                value={article.subtitle}
                onChange={handleState}
                placeholder="Subtitle"
                className="editorInput editorInput__h3"
                autoComplete="off"
              />
              <input
                name="excerpt"
                value={article.excerpt}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    excerpt: e.target.value,
                    seo_description: e.target.value
                  });
                }}
                placeholder="Excerpt"
                className="editorInput editorInput__h4"
                autoComplete="off"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <button
                  className="main-image__button"
                  aria-describedby={id}
                  onClick={(event) => handleClickPopover(event)}
                >
                  <Upload />
                  {!mainImage && !video && !mainGallery.length
                    ? 'Select main media'
                    : 'Change main media'}
                </button>

                <Popover
                  id={id}
                  anchorEl={anchorEl}
                  open={openPopover}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                >
                  <button
                    className="button__popover"
                    onClick={() => {
                      handleClosePopover();
                      setMediaType('image');
                      setMediaTypeTemp('image');
                      setOpenGallery(true);
                      setAllowMainImage(true);
                    }}
                  >
                    Image
                  </button>
                  <span className="button__popover--slicer">|</span>
                  <button
                    className="button__popover"
                    onClick={() => {
                      handleClosePopover();
                      setOpenModalVideo(true);
                      setMediaType('video');
                      setMediaTypeTemp('video');
                    }}
                  >
                    Video
                  </button>
                  <span className="button__popover--slicer">|</span>
                  <button
                    className="button__popover"
                    onClick={() => {
                      handleClosePopover();
                      setMediaType('gallery');
                      setMediaTypeTemp('gallery');
                      setAllowMainImage(false);
                      setOpenGallery(true);
                    }}
                  >
                    Gallery
                  </button>
                </Popover>
              </div>
              {mediaType === 'image' && mainImage ? (
                <img style={{ width: '100%' }} src={`${mainImage?.source}`} />
              ) : mediaType === 'video' ? (
                !openModalVideo && textToHTML(video)
              ) : mediaType === 'gallery' ? (
                <div className="editor__image-gallery">
                  {mainGallery.map((g) => (
                    <img key={g.source} src={g.source} />
                  ))}
                </div>
              ) : null}
            </div>
            {renderEditor && (
              <EditorJs
                data={article.content}
                instanceRef={(instance) => (instanceRef.current = instance)}
                // onChange={(data) => {
                //   if (data.blocks.getBlockByIndex(0).isEmpty)
                //     setArticle({ ...article, content: [] });
                //   else
                //     setArticle({
                //       ...article,
                //       content: { blocks: ['temp text'] }
                //     });
                // }}
                tools={editorTools}
              />
            )}
          </div>
        </ContainerWrapper>

        <aside
          className={`${
            isMobile && showAside
              ? 'block absolute z-10 h-full right-0'
              : 'hidden lg:block ml-6'
          }  w-96 overflow-x-hidden bg-white shadow rounded-lg`}
        >
          <div className="flex justify-between pt-3.5 px-4">
            <Button
              valid={params.id || article.slug ? true : false}
              onClick={() =>
                window.open(
                  `https://v2.6yka.com/${article.categories[0]?.label.toLowerCase()}/${
                    article.slug
                  }/preview`,
                  '_blank'
                )
              }
            >
              <span> Article preview </span>
            </Button>
            {!auth.isReader() && (
              <DropdownButton
                items={[
                  {
                    name: 'Save',
                    onClick: params.id || article.id ? EditArticle : AddArticle
                  },
                  ...(auth.isAdmin() || auth.isEditor()
                    ? [
                        {
                          name: 'Save and publish',
                          onClick:
                            params.id || article.id
                              ? () => EditArticle(true)
                              : () => AddArticle(true)
                        }
                      ]
                    : [])
                ]}
                valid={valid}
              />
            )}
          </div>

          <Accordion header="ATTRIBUTES" expanded={true}>
            <MultiselectCreate
              label="Tags"
              option="tags"
              createOption={CreateTag}
              defaultValue={article.tags}
              isAddingInProgress={isAddingInProgress}
              setSelectedOption={(value) =>
                setArticle({
                  ...article,
                  tags: value,
                  seo_keywords: value.map((v) => v.label).toString()
                })
              }
            />
            <MultiselectCreate
              label="Authors"
              option="authors"
              defaultValue={article.authors}
              createOption={CreateAuthor}
              isAddingInProgress={isAddingInProgress}
              setSelectedOption={(value) =>
                setArticle({ ...article, authors: value })
              }
            />
            <Select
              label="Categories*"
              options={categories}
              defaultValue={article.categories}
              setSelectedOption={(value) =>
                setArticle({ ...article, categories: value })
              }
            />
            {params.id ? (
              <p className="block text-sm font-medium text-gray-700 mb-3">
                Read time:{' '}
                <span className="text-gray-900 font-medium">
                  {article.read_time}
                </span>
              </p>
            ) : null}
            <Select
              label="Article type*"
              isClearable={false}
              options={articleTypes}
              defaultValue={article.article_type}
              setSelectedOption={(value) =>
                setArticle({ ...article, article_type: value })
              }
            />
            <MultiselectAsync
              label="Related articles"
              option="articles"
              defaultValue={article.related_articles}
              setSelectedOption={(value) =>
                setArticle({ ...article, related_articles: value })
              }
            />
            <Select
              label="Template*"
              isClearable={false}
              options={templates}
              defaultValue={article.template}
              setSelectedOption={(value) =>
                setArticle({ ...article, template: value })
              }
            />
            {!auth.isReader() && (
              <Select
                label="Status*"
                isClearable={false}
                options={statuses}
                defaultValue={article.status}
                setSelectedOption={(value) =>
                  setArticle({ ...article, status: value })
                }
              />
            )}
          </Accordion>
          {article.article_type?.allowed_settings?.locations ? (
            <Accordion header="INFO GUIDE" expandIcon={true}>
              <MultiselectAsync
                label="Locations"
                option="locations"
                setSelectedOption={(value) =>
                  setArticle({ ...article, locations: value })
                }
              />
              <DatetimePicker
                className="mb-3"
                label="Starts at"
                date={startsAt}
                setDate={setStartsAt}
              />
              <DatetimePicker
                className="mb-3"
                label="Ends at"
                date={endsAt}
                setDate={setEndsAt}
              />
            </Accordion>
          ) : null}
          <Accordion header="DYNAMIC ATTRIBUTES" expandIcon={true}>
            {renderDynamicAttributes()}
          </Accordion>
          {(auth.isAdmin() || auth.isEditor()) && (
            <Accordion header="OPTIONS" expandIcon={true}>
              <DatetimePicker
                className="mb-3"
                label="Published at"
                date={publishedDate}
                setDate={setPublishedDate}
                // disabled={article?.id && publishedDate}
              />
              <DatetimePicker
                className="mb-3"
                label="Expires at"
                date={expiredDate}
                setDate={setExpiredDate}
              />
              {article?.id ? (
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-1.5 justify-center
                border border-transparent shadow-sm text-sm font-medium rounded text-white bg-indigo-600-custom focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={ChangeStatus}
                >
                  <span> Unpublish article </span>
                </button>
              ) : null}
            </Accordion>
          )}
          <Accordion header="SEO OPTIMIZATON" expandIcon={true}>
            <Seo data={article} handleState={handleState} />
            {thumbnailImage.length ? (
              <div
                onClick={() => {
                  setWrapperId(-1);
                  setMediaTypeTemp('thumbnail_image');
                  setOpenGallery(true);
                  setThumbnail(true);
                }}
              >
                <img
                  style={{ width: '100%' }}
                  src={`${thumbnailImage[0]?.source}`}
                />
                <p className="border border-gray-300 text-center rounded-b-md mb-3 text-sm py-2 font-medium cursor-pointer hover:text-gray-500">
                  Change thumbnail image
                </p>
              </div>
            ) : (
              <button
                className="main-image__button"
                onClick={() => {
                  setWrapperId(-1);
                  setMediaTypeTemp('thumbnail_image');
                  setOpenGallery(true);
                  setThumbnail(true);
                }}
              >
                <Upload />
                Select thumbnail image
              </button>
            )}
            <MediaGallery
              open={openGallery}
              setOpen={setOpenGallery}
              setMainImage={setMainImage}
              allowMainImage={allowMainImage}
              thumbnail={thumbnail}
              gallery={
                wrapperId != -1
                  ? gallery
                  : mediaTypeTemp === 'thumbnail_image'
                  ? thumbnailImage
                  : mainGallery
              }
              setGallery={
                wrapperId != -1
                  ? setGallery
                  : mediaTypeTemp === 'thumbnail_image'
                  ? setThumbnailImage
                  : setMainGallery
              }
            />
            {article.id ? (
              <Input
                label="Slug"
                name="slug"
                className="mb-3"
                value={article.slug}
                handleChange={handleState}
              />
            ) : null}
            {article.id ? (
              <p className="block text-sm text-gray-500 mb-4">
                Canonical URL:{' '}
                <span className="text-gray-900 font-medium">
                  {article.canonical_url}
                </span>
              </p>
            ) : null}
          </Accordion>
          {(params.id || article.id) &&
          !dynamicAttributes.find((el) => el.name === 'main_article')
            ?.checked ? (
            <p
              className="text-sm font-bold text-red-500 p-5 cursor-pointer text-right"
              onClick={() => setOpenModal(true)}
            >
              DELETE ARTICLE
            </p>
          ) : null}
        </aside>
      </div>
    </Fragment>
  );
};

export default AddArticle;
