import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DropdownButton({ items, valid = true }) {
  const handleClick = (e, onClick) => {
    e.preventDefault();
    onClick();
  };

  return (
    <span className="relative z-10 inline-flex shadow-sm rounded-md">
      {valid ? (
        <Menu as="span" className="-ml-px relative block">
          {({ open }) => (
            <>
              <Menu.Button className="relative inline-flex items-center px-5 py-1.5 rounded border border-transparent bg-green-600-custom text-sm font-medium text-white hover:bg-green-600-hover focus:z-10 focus:outline-none ">
                <span>Save changes</span>{' '}
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {items.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={(e) => handleClick(e, item.onClick)}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900 cursor-pointer'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      ) : (
        <button
          variant="contained"
          disabled
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-5 py-1.5 bg-gray-200 text-base font-medium text-gray-400 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm cursor-not-allowed  justify-center"
        >
          <span>Save changes</span>{' '}
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </span>
  );
}
