import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-js-loader';
import moment from 'moment';
import queryString from 'query-string';
import APIService from '../../APIService';

import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/solid';
import { PencilAltIcon, LockClosedIcon, EyeIcon } from '@heroicons/react/outline';

import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Pagination from '../../components/pagination/pagination';
import NotFound from '../../assets/images/notFound.png';
import Modal from '../../components/modal/customModal';
import SubmitButton from '../../components/buttons/SubmitButton';

import config from '../../config/api.config';
import auth from '../../auth';

const Articles = () => {
  const history = useHistory();
  const location = useLocation();
  const pages = [{ name: 'Articles', href: '/articles', current: true }];

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 12,
    total: 0
  });
  const [open, setOpen] = useState(false);
  const [article, setArticle] = useState(null);

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetArticles({
      page: newPage
    });
  };

  const GetArticles = async (params) => {
    const result = await APIService.Get('articles', { ...params });
    setArticles(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
    setLoading(false);
  };

  const setEditedBy = async (id) => {
    const result = await APIService.Update(`${id}/edited-by`, 'articles', {
      being_edited_by: auth.getId()
    });
  };

  useEffect(() => {
    let params = queryString.parse(location.search);

    setLoading(true);
    GetArticles({ search: params.search ?? '' });
  }, [location.search]);


  return (
    <Fragment>
      <Modal open={open} setOpen={setOpen}>
        <p className="text-xl font-medium text-gray-500">
          This article is edited by{' '}
          <span className="bg-indigo-600-color">
            {article?.being_edited_by.first_name}{' '}
            {article?.being_edited_by.last_name}
          </span>
          .{' '}
          {auth.isAdmin()
            ? 'Do you still want to edit?'
            : "You don't have access right now."}
        </p>
        {auth.isAdmin() ? (
          <div className="flex justify-center mt-8">
            <button
              type="button"
              className="w-full mr-4 inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-6 py-1.5 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
            >
              No
            </button>
            <SubmitButton
              valid={true}
              onClick={() => {
                setEditedBy(article.id);
                history.push({
                  pathname: `/edit-article/${article.id}`,
                  state: {
                    redirect: true
                  }
                });
              }}
            >
              Yes{' '}
            </SubmitButton>
          </div>
        ) : null}
      </Modal>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={() => history.push('/add-article')}>
                  Create new article
                </AddButton>
              </div>
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="/edit-article" data={articles}>
                <Fragment>
                  {articles.length ? (
                    articles.map((article) => (
                      <li
                        key={article.id}
                        onClick={() => {
                          if (
                            article.being_edited_by &&
                            auth.getId() != article.being_edited_by.id
                          ) {
                            setArticle(article);
                            setOpen(true);
                          } else {
                            setEditedBy(article.id);
                            history.push({
                              pathname: `/edit-article/${article.id}`,
                              state: {
                                redirect: true
                              }
                            });
                          }
                        }}
                      >
                        <div className="block cursor-pointer hover:bg-gray-50">
                          <div
                            className="px-4 py-4 flex items-center sm:px-6 relative"
                            style={{
                              backgroundColor:
                                article.being_edited_by &&
                                auth.getId() != article.being_edited_by.id
                                  ? 'rgba(107, 114, 128,0.05)'
                                  : moment(article.published_at).isAfter(
                                      new Date()
                                    )
                                  ? '#bbcef738'
                                  : '#fff'
                            }}
                          >
                            {article.being_edited_by &&
                              auth.getId() != article.being_edited_by.id && (
                                <div
                                  className="absolute"
                                  style={{ left: '-30px' }}
                                >
                                  <LockClosedIcon className="h-5 w-5 text-gray-400" />
                                </div>
                              )}
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate flex align-center">
                                <div className="flex-shrink-0 mr-5">
                                  <img
                                    className="h-12 w-12 rounded-full object-cover"
                                    alt=""
                                    src={
                                      article.thumbnail_image &&
                                      (!JSON.parse(
                                        article.thumbnail_image
                                      ).data.files[0].url.includes(
                                        config.webUrl
                                      )
                                        ? `${config.webUrl}/${
                                            JSON.parse(article.thumbnail_image)
                                              .data.files[0].url
                                          }`
                                        : JSON.parse(article.thumbnail_image)
                                            .data.files[0].url)
                                    }
                                  />
                                </div>
                                <div>
                                  <div className="flex text-sm">
                                    <p className="font-medium text-indigo-600 truncate">
                                      {article.title}
                                    </p>
                                    <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                                      {' '}
                                      {`${article.created_by?.first_name} ${article.created_by?.last_name}`}
                                    </p>
                                  </div>
                                  <div className="mt-2 flex">
                                    {article.published_at ? (
                                      <div className="flex items-center text-sm text-gray-500">
                                        <CalendarIcon
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <p>
                                          {moment(article.published_at).isAfter(
                                            new Date()
                                          )
                                            ? 'Coming up '
                                            : 'Published at '}
                                          <time dateTime={'2020-01-07'}>
                                            {moment(
                                              article.published_at
                                            ).format('DD-MM-YYYY HH:mm')}
                                          </time>
                                        </p>
                                      </div>
                                    ) : (
                                      <p className="flex items-center text-sm text-gray-500">
                                        Not published yet
                                      </p>
                                    )}
                                    <div className='px-2 text-gray-500'>|</div>
                                  <div className="flex items-center text-sm text-gray-500">
                                  <EyeIcon
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                    <p className='pr-1'>View count:</p>{' '}
                                    <p>{article.view_count}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5 flex flex-col items-end">
                                <p
                                  className="text-sm font-medium text-indigo-700 hover:bg-indigo-50 px-2 rounded"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    history.push({
                                      pathname: `/article/${article.id}/comments`,
                                      state: article.title
                                    });
                                  }}
                                >
                                  See all comments
                                </p>
                                {article.updated_by && (
                                  <div className="text-sm text-gray-500 mt-2 flex items-center">
                                    <PencilAltIcon className="h-4 w-4 mr-1" />
                                    <p>
                                      Last edited by{' '}
                                      <span className="text-indigo-600">
                                        {article.updated_by?.first_name}{' '}
                                        {article.updated_by?.last_name}
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="ml-5 flex-shrink-0">
                              <ChevronRightIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Articles;
