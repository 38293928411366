import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as icons from '@heroicons/react/outline';
import { classNames } from '../../components/functions/functions';

import '../sidebar/sidebar.css';

const MobileMenu = ({ mobileMenuOpen, setMobileMenuOpen, t, menu }) => {
  const location = useLocation();
  const [current, setCurrent] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [sidebarNavigation, setSidebarNavigation] = useState([]);

  useEffect(() => {
    if (sidebarNavigation.find((el) => el.url === location?.pathname)) {
      setCurrent(location.pathname);
    }

    setSidebarNavigation(
      menu?.map((x) => {
        return { ...x, icon: icons[x.icon] };
      })
    );
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setCurrent(panel);
  };

  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="md:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full button--add pt-5 pb-4 flex-1 flex flex-col">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-50"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-1 right-0 -mr-14 p-1">
                  <button
                    type="button"
                    className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 px-4 flex items-center">
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                <nav className="h-full flex flex-col">
                  <div className="space-y-1">
                    {sidebarNavigation?.map((item) =>
                      item.children.length ? (
                        <Accordion
                          key={item.url}
                          className="mobile-menu__accordion"
                          expanded={expanded === item.url}
                          onChange={handleChange(item.url)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classNames(
                              current === item.url
                                ? 'accordion-open text-white'
                                : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                              'mobile-menu__accordion-summary text-sm font-medium'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                current === item.url
                                  ? 'text-white'
                                  : 'text-indigo-300 group-hover:text-white',
                                'mr-3 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            <span>{t(item.name)}</span>
                          </AccordionSummary>
                          <AccordionDetails className="flex-col mobile-menu__accordion-details font-medium text-sm">
                            {item.children.map((child) => (
                              <NavLink
                                key={child.name}
                                to={child.url}
                                onClick={() => setMobileMenuOpen(false)}
                              >
                                {t(child.name)}
                              </NavLink>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <NavLink
                          key={item.name}
                          to={item.url}
                          className={classNames(
                            current === item.url
                              ? 'bg-indigo-800 text-white'
                              : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                            'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                          onClick={() => {
                            setCurrent(item.url);
                            setMobileMenuOpen(false);
                            setExpanded(item.href);
                          }}
                        >
                          <item.icon
                            className={classNames(
                              current === item.url
                                ? 'text-white'
                                : 'text-indigo-300 group-hover:text-white',
                              'mr-3 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          <span>{t(item.name)}</span>
                        </NavLink>
                      )
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => ({ ...state.menu });

export default withNamespaces()(connect(mapStateToProps, null)(MobileMenu));
