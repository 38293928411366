export class CustomPoll {
  constructor({ data, config }) {
    this.data = data;
    this.config = config;
    this.wrapper = undefined;
  }

  static get toolbox() {
    return {
      title: 'Poll',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
      <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd" />
    </svg>`
    };
  }

  _createPreview(data) {
    const _data = JSON.parse(data);
    const div = document.createElement('div');
    div.innerHTML = `Poll - ${_data.question}`;
    div.setAttribute('data-poll', data);
    div.classList.add('poll-view');
    this.wrapper.innerHTML = '';
    this.wrapper.appendChild(div);
  }

  _createList(data, select) {
    data.forEach((element) => {
      const option = document.createElement('option');
      option.text = element.question;
      option.value = element.id;
      option.setAttribute('data-poll', JSON.stringify(element));
      select.appendChild(option);
    });
  }

  async _createDropdown() {
    const select = document.createElement('select');
    select.classList.add('editor__custom-select');
    let polls = await this.config.getPolls({ page: 1 });
    const polls_array = polls.data;
    console.log(polls);

    this._createList(polls_array, select);

    select.addEventListener('change', (event) =>
      this._createPreview(
        event.target.selectedOptions[0].getAttribute('data-poll')
      )
    );

    select.addEventListener('focus', async (e) => (select.size = 7));
    select.addEventListener('blur', async (e) => (select.size = 1));
    select.addEventListener('scroll', async (event) => {
      if (
        Math.floor(
          event.currentTarget.scrollHeight - event.currentTarget.scrollTop
        ) <= event.currentTarget.clientHeight &&
        polls.meta.current_page <= polls.meta.last_page
      ) {
        const data = await this.config.getPolls({
          page: ++polls.meta.current_page
        });
        this._createList(data.data, select);
        polls = data;
        polls_array.push(data.data);
      }
    });

    this.wrapper.innerHTML = '';
    this.wrapper.appendChild(select);
  }

  render() {
    this.wrapper = document.createElement('div');
    this._createDropdown();
    return this.wrapper;
  }

  save(blockContent) {
    if (blockContent.querySelector('div div'))
      return JSON.parse(
        blockContent.querySelector('div div').getAttribute('data-poll')
      );
    else return this.data;
  }
}
