import React from 'react';

const Button = ({
  onClick,
  className,
  valid = true,
  children,
  ...otherProps
}) => {
  return (
    <div className={className}>
      {valid ? (
        <button
          onClick={onClick}
          variant="contained"
          className="inline-flex items-center px-5 py-1.5 
          border border-transparent shadow-sm text-sm font-medium rounded text-white bg-indigo-600-custom focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          {...otherProps}
        >
          {children}
        </button>
      ) : (
        <button
          variant="contained"
          disabled
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-1.5 bg-gray-200 text-base font-medium text-gray-400 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm cursor-not-allowed"
          {...otherProps}
        >
          {children}
        </button>
      )}
    </div>
  );
};

export default Button;
