import React, { useState, Fragment } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ setColor }) => {
  const [colorPicker, setColorPicker] = useState({
    displayColorPicker: false,
    color: { r: '241', g: '112', b: '19', a: '1' }
  });

  const styles = reactCSS({
    default: {
      color: {
        width: '100%',
        height: '22px',
        borderRadius: '2px',
        background: `rgba(${colorPicker.color.r}, ${colorPicker.color.g}, ${colorPicker.color.b}, ${colorPicker.color.a})`
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        width: '100%'
      },
      popover: {
        position: 'absolute',
        zIndex: '2'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    }
  });

  const handleClick = (_) =>
    setColorPicker({
      ...colorPicker,
      displayColorPicker: !colorPicker.displayColorPicker
    });

  const handleClose = (_) =>
    setColorPicker({ ...colorPicker, displayColorPicker: false });

  const handleChange = (color) => {
    setColorPicker({ ...colorPicker, color: color.rgb });
    setColor(color);
  };

  return (
    <Fragment>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {colorPicker.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={colorPicker.color} onChange={handleChange} />
        </div>
      ) : null}
    </Fragment>
  );
};

export default ColorPicker;
