import React, { useState, useEffect, Fragment } from 'react';
import Loader from 'react-js-loader';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import NotFound from '../../assets/images/notFound.png';
import Error from '../../components/alerts/error';
import APIService from '../../APIService';

const Locations = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const pages = [{ name: 'Locations', href: '/locations', current: true }];
  const [name, setName] = useState('');
  const [header, setHeader] = useState('Add location');
  const [locationId, setLocationId] = useState(null);
  const [locations, setLocations] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const DeleteLocation = async () => {
    const result = await APIService.Delete(locationId, 'locations');
    setAlert({
      header: 'Successfully deleted!',
      message: 'This location is no longer available.'
    });
    setShowAlert(true);
    GetLocations({ page: params.current_page });
    setLocationId(null);
  };

  const GetLocations = async (params) => {
    const result = await APIService.Get('locations', { ...params });
    setLoading(false);
    setLocations(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  const AddLocation = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Insert('locations', { name });
      setButtonClicked(false);
      setOpenModal(false);
      setAlert({
        header: 'Successfully added!',
        message: 'You have successfully added new location.'
      });
      setShowAlert(true);
      GetLocations({ page: params.current_page });
    } catch (err) {
      setButtonClicked(false);
      console.error(err);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const EditLocation = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Update(locationId, 'locations', { name });
      GetLocations({ page: params.current_page });
      setLocationId(null);
      setButtonClicked(false);
      setOpenModal(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully edited!',
        message: 'You have successfully edited location.'
      });
    } catch (err) {
      setButtonClicked(false);
      console.error(err);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetLocations({
      page: newPage
    });
  };

  useEffect(() => {
    setLoading(true);
    GetLocations({ page: 1 });
  }, []);

  useEffect(() => {
    if (!openModal) setLocationId(null);
  }, [openModal]);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete location"
        deleteFun={DeleteLocation}
      >
        Are you sure you want to delete this location? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        save={locationId ? EditLocation : AddLocation}
        header={header}
        valid={name}
        buttonClicked={buttonClicked}
      >
        <FormInput
          label="Name"
          name="location"
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </Modal>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton
                  onClick={() => {
                    setOpenModal(true);
                    setHeader('Add location');
                    setName('');
                  }}
                >
                  Create new location
                </AddButton>
              </div>
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                {locations.length ? (
                  locations.map((location) => (
                    <li key={location.id}>
                      <div className="block hover:bg-gray-50 flex justify-between">
                        <div
                          className="px-4 py-5 flex items-center sm:px-6 w-full"
                          onClick={() => {
                            setOpenModal(true);
                            setName(location.name);
                            setLocationId(location.id);
                            setHeader('Edit location');
                          }}
                        >
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {location.name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                            {' '}
                            Edit{' '}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setLocationId(location.id);
                          }}
                          className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                        >
                          Delete
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <img className="img-not-found" src={NotFound} />
                )}
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Locations;
