import React, { useState } from 'react';

import Sidebar from './layout/sidebar/sidebar';
import MobileMenu from './layout/mobile/menu';
import Header from './layout/header/header';

import './App.css';
import 'tailwindcss/tailwind.css';
import 'react-datepicker/dist/react-datepicker.css';

function App({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="h-screen bg-gray-50 flex overflow-hidden">
      <Sidebar />
      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header setMobileMenuOpen={setMobileMenuOpen} />

        {/* Main content */}
        <div
          id="main-content"
          className="w-full p-2 flex-1 flex items-stretch overflow-x-hidden sm:p-8 flex-col self-center"
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default App;
