import React, { useCallback, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import { withAsyncPaginate } from 'react-select-async-paginate';

import axios from 'axios';
import config from '../../config/api.config';

import './select.css';

const increaseUniq = (uniq) => uniq + 1;

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

const defaultAdditional = {
  page: 1
};

const CreatableMulti = ({
  label,
  option = '',
  setSelectedOption,
  defaultValue,
  createOption,
  isAddingInProgress
}) => {
  const [cacheUniq, setCacheUniq] = useState(0);

  const loadPageOptions = async (q, prevOptions, { page }) => {
    const response = await axios.get(`${config.apiUrlBuka}/${option}`, {
      params: { page, search: q }
    });

    return {
      options: response.data.data.map((d) => {
        return { value: d.id, label: d.name ?? d.full_name };
      }),
      hasMore: response.data.meta.current_page !== response.data.meta.last_page,

      additional: {
        page: page + 1
      }
    };
  };

  const onCreateOption = useCallback(async (inputValue) => {
    await createOption(inputValue);
    setCacheUniq(increaseUniq);
  });

  return (
    <div className="w-full mb-3">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <CreatableAsyncPaginate
        isMulti
        isSearchable={true}
        isDisabled={isAddingInProgress}
        debounceTimeout={300}
        onChange={setSelectedOption}
        additional={defaultAdditional}
        value={defaultValue}
        loadOptions={loadPageOptions}
        onCreateOption={onCreateOption}
        cacheUniqs={[cacheUniq]}
        className="w-full mt-0.5 basic-multi-select"
        textFieldProps={{
          label: 'Label',
          InputLabelProps: {
            shrink: true
          }
        }}
      />
    </div>
  );
};

export default CreatableMulti;
