import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './App';
import './i18n';

import { routes } from './route';
import storeRoot from './redux/store';
import config from './config/api.config.js';
import { DefaultProtection } from './components/defaultProtection/defaultProtection';

import Login from './pages/Login/Login';

import './axios';

import 'tailwindcss/tailwind.css';

const { store, persistor } = storeRoot();

const Root = () => {
  return (
    <Fragment>
      <Switch>
        <Route path={`${config.publicUrl}/login`} component={Login} />

        <App>
          <Route exact path={`/`} render={() => <Redirect to="/dashboard" />} />
          {routes.map(({ path, Component }) => (
            <DefaultProtection
              key={path}
              exact
              path={`${config.publicUrl}${path}`}
              component={Component}
            />
          ))}
        </App>

        <Route path="*" render={() => <Redirect to="/login" />} />
      </Switch>
    </Fragment>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
