import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Loader from 'react-js-loader';

import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';

import config from '../../config/api.config';
import MultiselectAsync from '../../components/multiselect/multiselectAsync';

const Tenants = () => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [tenants, setTenants] = useState([]);
  const [tenant, setTenant] = useState({
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    features: []
  });
  const [header, setHeader] = useState('Add tenant');
  const [openModal, setOpenModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const pages = [{ name: 'Tenants', href: '/tenants', current: true }];
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const handleChange = (e) =>
    setTenant({ ...tenant, [e.target.name]: e.target.value });

  const handleAddButton = () => {
    GetTenantsCreate();
    setOpenModal(true);
    setHeader('Add tenant');
    setTenant({});
  };
  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetTenants({
      page: newPage
    });
  };

  const GetTenants = async (params) => {
    await axios
      .get(`${config.apiUrl}/tenants`, {
        params: {
          ...params
        }
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setTenants(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const GetTenantsCreate = async () => {
    await axios
      .get(`${config.apiUrl}/tenants/create`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const GetTenantsEdit = async (id) => {
    await axios
      .get(`${config.apiUrl}/tenants/${id}/edit`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const AddTenant = async () => {
    setButtonClicked(true);
    await axios
      .post(`${config.apiUrl}/tenants`, {
        id: tenant.id,
        domain: tenant.id,
        first_name: tenant.first_name,
        last_name: tenant.last_name,
        email: tenant.email,
        phone: tenant.phone,
        features: tenant.features
      })
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setAlert({
          header: 'Successfully added!',
          message: 'You have successfully added new tenant.'
        });
        setLoading(false);
        GetTenants({ page: params.current_page });
      })
      .catch((err) => {
        setButtonClicked(false);
        setLoading(false);
        console.error(err);
      });
  };

  const EditTenant = async () => {
    setButtonClicked(true);
    await axios
      .put(`${config.apiUrl}/tenants/${tenant.id}`, {
        features: []
      })
      .then((res) => {
        GetTenants({ page: params.current_page });
        setButtonClicked(false);
        setOpenModal(false);
        setShowAlert(true);
        setAlert({
          header: 'Successfully edited!',
          message: 'You have successfully edited tenant.'
        });
      })
      .catch((err) => {
        setButtonClicked(false);
        console.error(err);
      });
  };

  const DeleteTenant = async () => {
    await axios
      .delete(`${config.apiUrl}/tenants/${tenant.id}`)
      .then((res) => {
        setAlert({
          header: 'Successfully deleted!',
          message: 'This tenant is no longer available.'
        });
        setShowAlert(true);
        GetTenants({ page: params.current_page });
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setLoading(true);
    GetTenants({ page: 1 });
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete tenant"
        deleteFun={DeleteTenant}
      >
        Are you sure you want to delete this tenant? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        save={tenant.id ? EditTenant : AddTenant}
        header={header}
        valid={true}
        buttonClicked={buttonClicked}
      >
        <FormInput
          label="Name"
          name="id"
          className="mb-3"
          value={tenant.id}
          handleChange={handleChange}
        />
        <p className="block text-sm  text-gray-500 mb-4">
          Domain: <span className="text-gray-900 font-medium">{tenant.id}</span>
        </p>
        <MultiselectAsync
          label="Features"
          option="features"
          url={config.apiUrl}
          setSelectedOption={(value) =>
            setTenant({ ...tenant, features: value })
          }
        />

        <FormInput
          label="First name"
          name="first_name"
          className="mb-3"
          value={tenant.first_name}
          handleChange={handleChange}
        />
        <FormInput
          label="Last name"
          name="last_name"
          className="mb-3"
          value={tenant.last_name}
          handleChange={handleChange}
        />
        <FormInput
          label="Email"
          name="email"
          className="mb-3"
          value={tenant.email}
          handleChange={handleChange}
        />
        <FormInput
          label="Phone"
          name="phone"
          className="mb-3"
          value={tenant.phone}
          handleChange={handleChange}
        />
      </Modal>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={handleAddButton}>
                  Create new tenant
                </AddButton>
              </div>
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                {tenants?.map((tenant) => (
                  <li key={tenant.id}>
                    <div className="block hover:bg-gray-50 flex justify-between">
                      <div
                        className="px-4 py-5 flex items-center sm:px-6 w-full"
                        onClick={() => {
                          setOpenModal(true);
                          setHeader('Edit tenant');
                        }}
                      >
                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                          <div className="truncate">
                            <div className="flex text-sm">
                              <p className="font-medium text-gray-600 truncate">
                                {tenant.id}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                          {' '}
                          Edit{' '}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setTenant(tenant);
                        }}
                        className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                      >
                        Delete
                      </div>
                    </div>
                  </li>
                ))}
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Tenants;
