import React from 'react';
import Profile from '../../assets/images/profile.png';
import moment from 'moment';

const CommentCard = ({ comment, DeleteComment }) => {
  return (
    <div className="flex items-center w-full px-6 mb-6">
      <div
        className="w-10 h-10 bg-cover mr-4"
        style={{ backgroundImage: `url(${Profile})` }}
      ></div>
      <div className="bg-white shadow rounded-lg px-4 py-3 w-4/5 text-gray-500 relative">
        <p className="text-gray-500 text-lg mb-3">{comment.content}</p>
        <div className="flex items-center bg-indigo-600-color text-sm">
          <p>{comment.made_by.display_name}</p>
          <label className="mx-2">|</label>
          <p>{moment(comment.created_at).format('DD.MM.YYYY hh:mm:ss')}</p>
        </div>
        <div
          onClick={() => DeleteComment()}
          className="text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer absolute right-6 inset-y-1/4 flex items-center"
        >
          Delete
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
