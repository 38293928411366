import React, { useState, useEffect, Fragment } from 'react';
import Loader from 'react-js-loader';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import NotFound from '../../assets/images/notFound.png';
import Seo from '../../components/seo/seoAccordion';
import Error from '../../components/alerts/error';
import APIService from '../../APIService';
import './tags.css';

const Tags = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [valid, setValid] = useState(false);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const pages = [{ name: 'Tags', href: '/tags', current: true }];
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [tag, setTag] = useState({
    id: null,
    name: '',
    slug: '',
    number_of_uses: 0
  });
  const [seo, setSeo] = useState({
    seo_title: '',
    seo_description: '',
    seo_keywords: [],
    canonical_url: ''
  });

  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const [header, setHeader] = useState('Add tag');

  const handleAddButton = () => {
    setOpenModal(true);
    setTag({
      id: null,
      name: '',
      number_of_uses: null,
      slug: ''
    });
    setSeo({
      seo_title: '',
      seo_description: '',
      seo_keywords: '',
      canonical_url: ''
    });
    setHeader('Add tag');
  };

  const handleSeoChange = (e) =>
    setSeo({ ...seo, [e.target.name]: e.target.value });

  const AddTag = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Insert('tags', {
        name: tag.name,
        seo_title: seo.seo_title,
        seo_description: seo.seo_description,
        seo_keywords: seo.seo_keywords
      });
      setButtonClicked(false);
      setOpenModal(false);
      setAlert({
        header: 'Successfully added!',
        message: 'You have successfully added new tag.'
      });
      GetTags({ page: params.current_page });
    } catch (err) {
      setButtonClicked(false);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const GetTags = async (params) => {
    const result = await APIService.Get('tags', { ...params });
    setLoading(false);
    setTags(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  const EditTag = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Update(tag.id, 'tags', {
        name: tag.name,
        seo_title: seo.seo_title,
        seo_description: seo.seo_description,
        seo_keywords: seo.seo_keywords,
        slug: tag.slug
      });
      GetTags({ page: params.current_page });
      setButtonClicked(false);
      setOpenModal(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully edited!',
        message: 'You have successfully edited tag.'
      });
    } catch (err) {
      setButtonClicked(false);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetTags({
      page: newPage
    });
  };

  const DeleteTag = async () => {
    const result = await APIService.Delete(tag.id, 'tags');
    setShowAlert(true);
    setAlert({
      header: 'Successfully deleted!',
      message: 'This tag is no longer available.'
    });
    GetTags({ page: params.current_page });
  };

  useEffect(() => {
    if (tag.name && seo.seo_title) {
      setValid(true);

      if (tag.id && !tag.slug) setValid(false);
    } else setValid(false);
  }, [tag.name, seo.seo_title, tag.slug]);

  useEffect(() => {
    setLoading(true);
    GetTags({ page: 1 });
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete tag"
        deleteFun={DeleteTag}
      >
        Are you sure you want to delete this tag? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>

      <Modal
        open={openModal}
        setOpen={setOpenModal}
        header={header}
        buttonClicked={buttonClicked}
        save={tag.id ? EditTag : AddTag}
        valid={valid}
      >
        <Fragment>
          {tag.id ? (
            <p className="block text-sm  text-gray-500 mb-4">
              Number of uses:{' '}
              <span className="text-gray-900 font-medium">
                {tag.number_of_uses}
              </span>
            </p>
          ) : null}
          <FormInput
            label="Name*"
            name="name"
            value={tag.name}
            handleChange={(e) => {
              setTag({ ...tag, name: e.target.value });
              setSeo({ ...seo, seo_title: e.target.value });
            }}
          />
          <p className="mt-6 mb-3 font-medium text-gray-400">SEO OPTIONS</p>
          {tag.id ? (
            <p className="block text-sm text-gray-500 mb-4">
              Canonical URL:{' '}
              <span className="text-gray-900 font-medium">
                {seo.canonical_url && JSON.parse(seo.canonical_url)}
              </span>
            </p>
          ) : null}
          <Seo data={seo} handleState={handleSeoChange} />
          {tag.id ? (
            <FormInput
              label="Slug"
              name="slug"
              className="mb-3"
              value={tag.slug}
              handleChange={(e) => setTag({ ...tag, slug: e.target.value })}
            />
          ) : null}
        </Fragment>
      </Modal>

      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={handleAddButton}>Create new tag</AddButton>
              </div>
            </div>

            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                <Fragment>
                  {tags.length ? (
                    tags.map((tag) => (
                      <li key={tag.id}>
                        <div className="block hover:bg-gray-50 flex justify-between">
                          <div
                            className="px-4 py-5 flex items-center sm:px-6 w-full"
                            onClick={() => {
                              setOpenModal(true);
                              setTag(tag);
                              setSeo({
                                seo_title: tag.seoInformation.title,
                                seo_description: tag.seoInformation.description,
                                seo_keywords: tag.seoInformation.keywords,
                                canonical_url: tag.seoInformation.canonical_url
                              });
                              setHeader('Edit tag');
                            }}
                          >
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    {tag.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    Number of uses: {tag.number_of_uses}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                              {' '}
                              Edit{' '}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setTag({ ...tag, id: tag.id });
                            }}
                            className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Tags;
