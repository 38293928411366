import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Loader from 'react-js-loader';
import DeleteModal from '../../components/deleteModal/deleteModal';
import NotFound from '../../assets/images/notFound.png';
import List from '../../components/stackedList/stackedList';
import APIService from '../../APIService';
import CommentCard from './commentCard';

const Comments = () => {
  const params = useParams();
  const location = useLocation();
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const pages = [
    { name: 'Articles', href: '/articles', current: false },
    { name: 'Comments', href: `/article/${params.id}/comments`, current: true }
  ];

  const DeleteComment = async () => {
    const result = await APIService.Delete(commentId, 'articles/comments');
    GetComments();
  };

  const GetComments = async () => {
    const result = await APIService.Get(`articles/${params.id}/comments`);
    setLoading(false);
    setComments(
      result.data
        .map((el) => {
          return {
            ...el,
            comments: result.data.filter((x) => x.parent_id === el.id)
          };
        })
        .filter((el) => el.parent_id === null)
    );
  };
  useEffect(() => {
    setLoading(true);
    GetComments();
  }, []);

  return (
    <Fragment>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete comment"
        deleteFun={DeleteComment}
      >
        Are you sure you want to delete this comment? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>

      <Breadcrumb pages={pages} />
      {loading ? (
        <div className="h-full flex items-center justify-center">
          <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
        </div>
      ) : (
        <div className="flex-1 flex flex-col">
          <p className="font-medium text-3xl my-8 bg-indigo-600-color">
            {location.state}
          </p>
          {comments.length ? (
            comments.map((comment) => (
              <div key={comment.id} className="w-full">
                <CommentCard
                  comment={comment}
                  DeleteComment={() => {
                    setCommentId(comment.id);
                    setOpenDeleteModal(true);
                  }}
                />
                <div className="ml-14">
                  {comment.comments.map((el) => (
                    <CommentCard
                      key={el.id}
                      comment={el}
                      DeleteComment={() => {
                        setCommentId(el.id);
                        setOpenDeleteModal(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            ))
          ) : (
            <List url="">
              <img className="img-not-found" src={NotFound} />
            </List>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Comments;
