import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import DeleteButton from '../../components/buttons/DeleteButton';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Success from '../../components/alerts/success';
import EditButton from '../../components/buttons/EditButton';
import SelectAsync from '../../components/multiselect/selectAsync';
import APIService from '../../APIService';

export default function MediaDetails({
  open,
  setOpen,
  currentFile,
  GetMedia,
  setCurrentMedia,
  setMedia
}) {
  const [openModal, setOpenModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });

  const DeleteMedia = async () => {
    const result = await APIService.Delete(currentFile.id, 'images');
    setOpen(false);
    setMedia([]);
    GetMedia({ page: 1 }, true);
    setAlert({
      header: 'Successfully deleted!',
      message: 'This media is no longer available.'
    });
    setShowAlert(true);
  };

  const EditImage = async (id) => {
    setButtonClicked(true);
    const result = await APIService.Update(id, 'images', {
      title: currentFile?.name,
      path: currentFile?.path,
      ...(currentFile?.description && {description: currentFile?.description}),
      author_id: currentFile?.author_id?.value
    });
    setAlert({
      header: 'Successfully edited!',
      message: 'You have successfully edited new media.'
    });
    GetMedia({ page: 1 }, true);
    setShowAlert(true);
    setOpen(false);
    setButtonClicked(false);
    setEditMode(false);
  };

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        header="Delete media"
        deleteFun={DeleteMedia}
      >
        Are you sure you want to delete this media? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-20"
          open={open}
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <h2
                          id="slide-over-heading"
                          className="text-lg font-medium text-gray-900"
                        >
                          Media details
                        </h2>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}

                    <aside className="w-100 bg-white p-8 border-gray-200 overflow-y-auto lg:block">
                      <div className=" space-y-6">
                        <div>
                          <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                            <img
                              src={currentFile?.source}
                              alt=""
                              className="object-cover mx-auto"
                            />
                          </div>
                          <div className="mt-4 flex items-start justify-between">
                            <div>
                              <h2 className="text-lg font-medium text-gray-900">
                                {currentFile?.name}
                              </h2>
                              <p className="text-sm font-medium text-gray-500">
                                {currentFile?.size}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900">
                            Information
                          </h3>
                          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                            {currentFile &&
                              Object.keys(currentFile?.information).map(
                                (key) => (
                                  <div
                                    key={key}
                                    className="py-3 flex justify-between text-sm font-medium"
                                  >
                                    <dt className="text-gray-500">{key}</dt>
                                    <dd className="text-gray-900">
                                      {currentFile?.information[key]}
                                    </dd>
                                  </div>
                                )
                              )}
                            {!editMode ? (
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Author</dt>
                                <dd className="text-gray-900">
                                  {currentFile?.author_id?.label}
                                </dd>
                              </div>
                            ) : null}
                          </dl>
                          {!editMode ? (
                            <Fragment>
                              <div className="pt-2.5 pb-5 text-sm">
                                <label className="text-gray-500 font-medium">
                                  Description
                                </label>
                                <p className="text-gray-700 italic">
                                  {' '}
                                  {currentFile?.description}
                                </p>
                              </div>
                            </Fragment>
                          ) : null}
                        </div>
                        {editMode && (
                          <Fragment>
                            <div className="mb-8">
                              <SelectAsync
                                label="Authors"
                                option="authors"
                                defaultValue={currentFile?.author_id}
                                setSelectedOption={(value) =>
                                  setCurrentMedia({
                                    ...currentFile,
                                    author_id: value
                                  })
                                }
                              />
                            </div>
                            <div>
                              <label htmlFor="description" className="sr-only">
                                About
                              </label>
                              <textarea
                                id="description"
                                name="description"
                                placeholder="Description"
                                value={currentFile?.description}
                                onChange={(e) =>
                                  setCurrentMedia({
                                    ...currentFile,
                                    description: e.target.value
                                  })
                                }
                                rows={3}
                                className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                              />
                            </div>
                          </Fragment>
                        )}

                        <div className="flex">
                          {editMode ? (
                            <EditButton
                              buttonClicked={buttonClicked}
                              className="flex-1 w-full self-center"
                              onClick={() => EditImage(currentFile?.id)}
                            >
                              {' '}
                              Save changes{' '}
                            </EditButton>
                          ) : (
                            <button
                              type="button"
                              className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600-custom focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => setEditMode(true)}
                            >
                              <span> Edit image </span>
                            </button>
                          )}
                          {editMode ? (
                            <button
                              onClick={() => setEditMode(false)}
                              type="button"
                              className="flex-1 ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Cancel
                            </button>
                          ) : (
                            <DeleteButton
                              className="flex-1 ml-3 py-2 px-4 justify-center"
                              onClick={DeleteMedia}
                            >
                              Delete
                            </DeleteButton>
                          )}
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
}
