import React, { useState } from 'react';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Success from '../../components/alerts/success';

export default function StackedList({ className, children }) {
  const [openModal, setOpenModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const DeleteArticle = () => {
    setShowAlert(true);
  };

  return (
    <div className={`flex flex-col w-full mt-6 ${className}`}>
      <Success
        show={showAlert}
        setShow={setShowAlert}
        header="Successfully deleted!"
      >
        This article is no longer available.
      </Success>
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        header="Delete article"
        deleteFun={DeleteArticle}
      >
        Are you sure you want to delete this article? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <div className="bg-white shadow  sm:rounded-md">
        <ul className="divide-y divide-gray-200 list">{children}</ul>
      </div>
    </div>
  );
}
