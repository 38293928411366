import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Loader from 'react-js-loader';

import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Select from '../../components/multiselect/select';
import NotFound from '../../assets/images/notFound.png';
import queryString from 'query-string';
import Error from '../../components/alerts/error';

import config from '../../config/api.config';
import { useLocation } from 'react-router-dom';

const Users = () => {
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [roles, setRoles] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const pages = [{ name: 'Users', href: '/users', current: true }];
  const [valid, setValid] = useState(false);
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });
  const [user, setUser] = useState({
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    role_id: null,
    status_id: null
  });
  const [header, setHeader] = useState('Add user');
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);

  const handleChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleAddButton = () => {
    setOpenModal(true);
    setHeader('Add user');
    setUser({
      id: null,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      role_id: null,
      status_id: null
    });
  };
  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetUsers({
      page: newPage
    });
  };

  const DeleteUser = async () => {
    await axios
      .delete(`${config.apiUrlBuka}/users/${user.id}`)
      .then((res) => {
        // console.log(res);
        setAlert({
          header: 'Successfully deleted!',
          message: 'This user is no longer available.'
        });
        setShowAlert(true);
        GetUsers({ page: params.current_page });
      })
      .catch((err) => console.error(err));
  };

  const GetUsers = async (params) => {
    await axios
      .get(`${config.apiUrlBuka}/users`, {
        params: {
          ...params
        }
      })
      .then((res) => {
        setLoading(false);
        setUsers(res.data.data);
        const { current_page, last_page, per_page, total } = res.data.meta;

        setParams({
          current_page,
          last_page,
          per_page,
          total
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const EditUser = async () => {
    setButtonClicked(true);
    await axios
      .put(`${config.apiUrlBuka}/users/${user.id}`, {
        role_id: user.role_id.value,
        status_id: user.status_id.value,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        password: user.password
      })
      .then((res) => {
        // console.log(res);
        GetUsers({ page: params.current_page });
        setButtonClicked(false);
        setOpenModal(false);
        setShowAlert(true);
        setAlert({
          header: 'Successfully edited!',
          message: 'You have successfully edited user.'
        });
      })
      .catch((err) => {
        setButtonClicked(false);
        console.error(err);
        setShowError(true);
        Object.entries(err.response.data.errors).forEach(([key, value]) =>
          setError([...value])
        );
      });
  };

  const AddUser = async () => {
    setButtonClicked(true);
    await axios
      .post(`${config.apiUrlBuka}/users`, {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        password: user.password,
        role_id: user.role_id.value
      })
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setAlert({
          header: 'Successfully added!',
          message: 'You have successfully added new user.'
        });
        setLoading(false);
        GetUsers({ page: params.current_page });
      })
      .catch((err) => {
        setButtonClicked(false);
        setLoading(false);
        setShowError(true);
        Object.entries(err.response.data.errors).forEach(([key, value]) =>
          setError([...value])
        );
        console.error(err);
      });
  };
  const GetUsersCreate = async () => {
    await axios
      .get(`${config.apiUrlBuka}/users/create`)
      .then((res) => {
        setRoles(
          res.data.roles.map((role) => {
            return { value: role.id, label: role.name };
          })
        );
      })
      .catch((err) => console.error(err));
  };

  const GetUserEdit = async (id) => {
    await axios
      .get(`${config.apiUrlBuka}/users/${id}/edit`)
      .then((res) => {
        setStatuses(
          res.data.statuses.map((s) => {
            return { value: s.id, label: s.name };
          })
        );

        const user = res.data.user;
        setUser({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: user.phone,
          role_id: user.roles[0]
            ? {
                value: user.roles[0].id,
                label: user.roles[0].name
              }
            : null,
          status_id: {
            value: user.status_id,
            label: res.data.statuses.find((s) => s.id === user.status_id).name
          },
          password: ''
        });
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setLoading(true);
    GetUsersCreate();
    GetUsers({ page: 1 });
  }, []);

  useEffect(() => {
    if (
      user.first_name &&
      user.last_name &&
      user.email &&
      user.role_id &&
      user.password
    ) {
      setValid(true);
      if (user.id && !user.status_id) setValid(false);
    } else setValid(false);
  }, [
    user.first_name,
    user.last_name,
    user.password,
    user.email,
    user.role_id,
    user.status_id
  ]);

  useEffect(() => {
    setLoading(true);
    let params = queryString.parse(location.search);

    GetUsers({ search: params.search });
  }, [location.search]);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete user"
        deleteFun={DeleteUser}
      >
        Are you sure you want to delete this user? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        save={user.id ? EditUser : AddUser}
        header={header}
        valid={valid}
        buttonClicked={buttonClicked}
      >
        <FormInput
          label="First name*"
          name="first_name"
          className="mb-3"
          // disabled={user.id}
          value={user.first_name}
          handleChange={handleChange}
        />
        <FormInput
          label="Last name*"
          name="last_name"
          // disabled={user.id}
          className="mb-3"
          value={user.last_name}
          handleChange={handleChange}
        />
        <Select
          label="Role*"
          options={roles}
          defaultValue={user.role_id}
          setSelectedOption={(value) => setUser({ ...user, role_id: value })}
          isClearable={false}
        />
        {user.id ? (
          <Select
            label="Status"
            options={statuses}
            defaultValue={user.status_id}
            setSelectedOption={(value) =>
              setUser({ ...user, status_id: value })
            }
            isClearable={false}
          />
        ) : null}
        <FormInput
          label="Email*"
          name="email"
          type="email"
          // disabled={user.id}
          className="mb-3"
          value={user.email}
          handleChange={handleChange}
        />
        <FormInput
          label="Phone"
          name="phone"
          className="mb-3"
          value={user.phone}
          handleChange={handleChange}
        />
        {/* {user.id ? null : ( */}
        <FormInput
          label="Password*"
          name="password"
          type="password"
          className="mb-3"
          value={user.password}
          handleChange={handleChange}
        />
        {/* )} */}
      </Modal>

      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={handleAddButton}>Create new user</AddButton>
              </div>
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                {users.length ? (
                  users.map((user) => (
                    <li key={user.id}>
                      <div className="block hover:bg-gray-50 flex justify-between">
                        <div
                          className="px-4 py-5 flex items-center sm:px-6 w-full"
                          onClick={() => {
                            GetUserEdit(user.id);
                            setHeader('Edit user');
                            setOpenModal(true);
                          }}
                        >
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {user.first_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {user.last_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {user.roles[0]?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                            {' '}
                            Edit{' '}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setUser(user);
                          }}
                          className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                        >
                          Delete
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <img className="img-not-found" src={NotFound} />
                )}
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Users;
