import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { classNames } from '../../components/functions/functions';

const Breadcrumb = (pages) => {
  return (
    <nav className="flex py-4 px-3" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        <li>
          <div>
            <Link
              to="/dashboard"
              className="bg-indigo-600-brighter-custom hover:bg-indigo-600-color flex"
            >
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              {!pages.pages.length ? (
                <p className="bg-indigo-600-color ml-1 text-sm font-medium bg-indigo-600-brighter-custom hover:bg-indigo-600-color">
                  Dashboard
                </p>
              ) : null}
            </Link>
          </div>
        </li>
        {pages.pages.map((page) => (
          <li key={page?.name}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 bg-indigo-600-brighter-custom"
                aria-hidden="true"
              />
              <Link
                to={page?.href}
                className={classNames(
                  page?.current ? 'bg-indigo-600-color' : '',
                  'ml-1 text-sm font-medium bg-indigo-600-brighter-custom hover:bg-indigo-600-color'
                )}
                aria-current={page?.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
