import React, { Fragment, useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import config from '../../config/api.config';
import ContainerWrapper from '../../layout/ContainerWrapper/ContainerWrapper';
import FormInput from '../../components/formInput/formInput';
import SubmitButton from '../../components/buttons/SubmitButton';
import { ReactComponent as ProfileImg } from '../../assets/images/editProfile.svg';
import Profile from '../../assets/images/profile.png';
import APIService from '../../APIService';
import { setUser } from '../../redux/user/action';
import { useDispatch} from 'react-redux'

const ProfilePage = () => {
  const dispatch = useDispatch();
  const pages = [{ name: 'Profile', href: '/profile', current: true }];
  const [user, setUserData] = useState({
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    display_name: '',
    phone: '',
    photo: null,
    photoThumb: null
  });
  const [buttonClicked, setButtonClicked] = useState(false);
  const fileRef = useRef();

  const GetData = async () => {
    const result = await APIService.Get('users/user/me');
    dispatch(setUser(result.data))
    setUserData({
      id: result.data.id,
      first_name: result.data.first_name ?? '',
      last_name: result.data.last_name ?? '',
      email: result.data.email ?? '',
      password: '',
      display_name: result.data.display_name ?? '',
      phone: result.data.phone ?? '',
      photo: result.data.image?.path ?? '',
      photoThumb: null
    });
  };

  const EditData = async () => {
    setButtonClicked(true);

    if (user.photo instanceof File) {
      const formData = new FormData();
      formData.append('photo', user.photo);
      formData.append('_method', 'PUT');
      const res = await APIService.Insert(
        `users/profile-photo-update/${user.id}`,
        formData
      );
      console.log('ohot update',res)
    }

    const result = await APIService.Update('update-my-profile', 'users/user', {
      first_name: user.first_name,
      last_name: user.last_name,
      display_name: user.display_name,
      email: user.email,
      password: user.password,
      phone: user.phone
    });
    setButtonClicked(false);
    GetData();
  };

  const handleChange = (e) =>
  setUserData({ ...user, [e.target.name]: e.target.value });

  const changeProfileImage = (e) => {
    var reader = new FileReader();
    reader.addEventListener(
      'load',
      function () {
        setUserData({
          ...user,
          photo: e.target.files[0],
          photoThumb: reader.result
        });
      },
      false
    );

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb pages={pages} />
      <div className="flex-1 flex">
        <ContainerWrapper
          className="p-9 pt-12"
          style={{ flexDirection: 'row', justifyContent: 'space-around' }}
        >
          <div style={{ width: '44%' }}>
            <div
              className="profile-settings__image mb-8"
              onClick={() => fileRef.current.click()}
            >
              <input
                type="file"
                accept="image/*"
                ref={fileRef}
                onChange={changeProfileImage}
              />
              <ProfileImg />
              <img
                src={
                  user.photoThumb ?? `${config.webUrl}/${user.photo}` ?? Profile
                }
              />
              <p>CHANGE PROFILE PICTURE</p>
            </div>
            <FormInput
              label="First name"
              name="first_name"
              className="mb-3"
              value={user.first_name}
              handleChange={handleChange}
            />
            <FormInput
              label="Last name"
              name="last_name"
              className="mb-3"
              value={user.last_name}
              handleChange={handleChange}
            />
          </div>
          <div style={{ width: '44%' }}>
            <FormInput
              label="Display name*"
              name="display_name"
              className="mb-3"
              value={user.display_name}
              handleChange={handleChange}
            />
            <FormInput
              label="Email*"
              name="email"
              type="email"
              disabled={user.id}
              className="mb-3"
              value={user.email}
              handleChange={handleChange}
            />
            <FormInput
              label="Phone"
              name="phone"
              className="mb-3"
              value={user.phone}
              handleChange={handleChange}
            />

            <FormInput
              label="Password"
              name="password"
              type="password"
              className="mb-3"
              value={user.password}
              handleChange={handleChange}
            />
            <div className="flex justify-end pt-5">
              <SubmitButton
                buttonClicked={buttonClicked}
                valid={
                  user.first_name &&
                  user.last_name &&
                  user.display_name &&
                  user.email &&
                  user.password
                }
                onClick={EditData}
              >
                Save changes
              </SubmitButton>
            </div>
          </div>
        </ContainerWrapper>
      </div>
    </Fragment>
  );
};

export default ProfilePage;
