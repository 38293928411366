import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../auth';
import LocalStorageService from '../../LocalStorageService';

export const DefaultProtection = ({ component: Component, ...otherProps }) => {
  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (
          auth.isAuthenticated() &&
          !auth.isTokenExpired() &&
          LocalStorageService.get('access_token')
        ) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
