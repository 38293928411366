import React, { Fragment, useState, useEffect } from 'react';
import Loader from 'react-js-loader';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import NotFound from '../../assets/images/notFound.png';
import Error from '../../components/alerts/error';
import APIService from '../../APIService';

const SocialNetworks = () => {
  const [values, setValues] = useState({
    id: null,
    title: '',
    url: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const pages = [
    { name: 'Social networks', href: '/social-networks', current: true }
  ];
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });
  const [header, setHeader] = useState('Add social network');
  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleAddButton = () => {
    setOpenModal(true);
    setHeader('Add social network');
    setValues({
      id: null,
      title: '',
      url: ''
    });
  };
  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetSocialNetworks({
      page: newPage
    });
  };

  const GetSocialNetworks = async (params) => {
    const result = await APIService.Get('social-networks', { ...params });
    setLoading(false);
    setSocialNetworks(result.data);
    const { current_page, last_page, per_page, total } = result.meta;

    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  const EditSocialNetwork = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Update(values.id, 'social-networks', {
        title: values.title,
        url: values.url
      });
      GetSocialNetworks({ page: params.current_page });
      setButtonClicked(false);
      setOpenModal(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully edited!',
        message: 'You have successfully edited social network.'
      });
    } catch (err) {
      setButtonClicked(false);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const DeleteSocialNetwork = async () => {
    const result = await APIService.Delete(values.id, 'social-networks');
    setAlert({
      header: 'Successfully deleted!',
      message: 'This social network is no longer available.'
    });
    setShowAlert(true);
    GetSocialNetworks({ page: params.current_page });
  };

  const AddSocialNetwork = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Insert('social-networks', {
        title: values.title,
        url: values.url
      });
      setButtonClicked(false);
      setOpenModal(false);
      setAlert({
        header: 'Successfully added!',
        message: 'You have successfully added new social network.'
      });
      setLoading(false);
      GetSocialNetworks({ page: params.current_page });
    } catch (err) {
      setButtonClicked(false);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    GetSocialNetworks({ page: 1 });
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete social network"
        deleteFun={DeleteSocialNetwork}
      >
        Are you sure you want to delete this network? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        save={values.id ? EditSocialNetwork : AddSocialNetwork}
        header={header}
        valid={values.title && values.url}
        buttonClicked={buttonClicked}
      >
        <FormInput
          label="Title*"
          name="title"
          className="mb-3"
          value={values.title}
          handleChange={handleChange}
        />
        <FormInput
          label="URL*"
          name="url"
          className="mb-3"
          value={values.url}
          handleChange={handleChange}
        />
      </Modal>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={handleAddButton}>
                  Create new network
                </AddButton>
              </div>
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              <List url="">
                {socialNetworks.length ? (
                  socialNetworks.map((network) => (
                    <li key={network.id}>
                      <div className="block hover:bg-gray-50 flex justify-between">
                        <div
                          className="px-4 py-5 flex items-center sm:px-6 w-full"
                          onClick={() => {
                            setOpenModal(true);
                            setValues(network);
                            setHeader('Edit social network');
                          }}
                        >
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {network.title}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-gray-600 truncate">
                                  {network.url}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                            {' '}
                            Edit{' '}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setValues(network);
                          }}
                          className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                        >
                          Delete
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <img className="img-not-found" src={NotFound} />
                )}
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default SocialNetworks;
