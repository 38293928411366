import { XCircleIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';

export default function ErrorAlert({ show, setShow, array }) {
  useEffect(() => {
    setTimeout(() => setShow(false), 6000);
  }, [show]);
  return (
    <>
      {show && (
        <div className="rounded-md bg-red-50 p-4 w-96 z-50 absolute top-7 right-7">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              {array.length > 1 ? (
                <h3 className="text-sm font-medium text-red-800">
                  There were {array.length} errors with your submission
                </h3>
              ) : (
                <h3 className="text-sm font-medium text-red-800">
                  There was {array.length} error with your submission
                </h3>
              )}
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc pl-5 space-y-1">
                  {array.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
