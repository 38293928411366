import config from '../../config/api.config';

export class CustomArticle {
  constructor({ data, config }) {
    this.data = data;
    this.config = config;
    this.wrapper = undefined;
  }

  static get pasteConfig() {
    return {
      patterns: {
        image: /https:\/\/6yka.com\S+/i
      }
    };
  }

  async _createArticle(url) {
    const div = document.createElement('div');
    div.classList.add('editor__custom-article');

    if (!url) return;

    const article = await this.config
      .getArticle(url.split('/').pop())
      .then((res) => res.data);
    const thumbnail_image = JSON.parse(article.thumbnail_image);

    const img = document.createElement('img');
    img.src = !thumbnail_image.data.files[0].url.includes(config.webUrl)
      ? `${config.webUrl}/${thumbnail_image.data.files[0].url}`
      : thumbnail_image.data.files[0].url;
    div.appendChild(img);

    const div_text = document.createElement('div');
    const title = document.createElement('p');
    title.innerHTML = article.title;

    const p = document.createElement('p');
    const category = document.createElement('span');
    category.classList.add('custom-article__category');
    category.innerHTML = article.categories[0].title;
    const author = document.createElement('span');
    author.innerHTML = ' | ' + article.authors[0].full_name;

    p.appendChild(category);
    p.appendChild(author);

    div_text.appendChild(title);
    div_text.appendChild(p);
    div.appendChild(div_text);

    const outputData = {
      slug: article.slug,
      id: article.id,
      title: article.title,
      thumbnail_image: article.thumbnail_image,
      excerpt: article.excerpt,
      created_at: article.created_at,
      author: article.authors,
      category: article.categories,
      url: url
    };
    div.setAttribute('data-article', JSON.stringify(outputData));

    this.wrapper.innerHTML = '';
    this.wrapper.appendChild(div);
  }

  onPaste(event) {
    switch (event.type) {
      case 'pattern':
        const url = event.detail.data;
        this._createArticle(url);
        break;
    }
  }

  render() {
    this.wrapper = document.createElement('div');
    this.data && this._createArticle(this.data.url);
    return this.wrapper;
  }

  save(blockContent) {
    if (blockContent.querySelector('div div'))
      return JSON.parse(
        blockContent.querySelector('div div').getAttribute('data-article')
      );
    else return this.data;
  }
}
