export class CustomH2 {
  constructor({ data }) {
    this.data = data;
  }

  // static get toolbox() {
  //     return {
  //       title: 'H2',
  //       icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.8em" height="1.8em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 7h2v10H9v-4H5v4H3V7h2v4h4V7zm8 8c.51-.41.6-.62 1.06-1.05c.437-.4.848-.828 1.23-1.28c.334-.39.62-.82.85-1.28c.2-.39.305-.822.31-1.26a3.01 3.01 0 0 0-.27-1.28a2.902 2.902 0 0 0-.76-1a3.485 3.485 0 0 0-1.17-.63a4.761 4.761 0 0 0-1.5-.22c-.36 0-.717.033-1.07.1c-.343.06-.678.158-1 .29a4.38 4.38 0 0 0-.86.49a8.88 8.88 0 0 0-.82.68l1.24 1.22a5.829 5.829 0 0 1 1-.7c.35-.2.747-.304 1.15-.3c.455-.03.906.106 1.27.38c.31.278.477.684.45 1.1c-.014.396-.14.78-.36 1.11a7.08 7.08 0 0 1-1 1.25c-.44.43-.98.92-1.59 1.43c-.61.51-1.41 1.06-2.16 1.65V17h8v-2h-4z" fill="currentColor"/></svg>'
  //     };
  //   }

  render() {
    const h2 = document.createElement('input');
    h2.placeholder = this.data?.placeholder ?? '';
    h2.style.fontSize = '1.5em';
    h2.style.padding = '0.7em 0';
    h2.style.border = 'none';
    h2.style.outline = 'none';
    h2.style.width = '100%';
    h2.value = this.data && this.data.text ? this.data.text : '';

    return h2;
  }

  save(blockContent) {
    return {
      text: blockContent.value
    };
  }
}
