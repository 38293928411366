import React, { Fragment, useState, useEffect } from 'react';
import Loader from 'react-js-loader';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import config from '../../config/api.config';
import Modal from '../../components/modal/modal';
import Success from '../../components/alerts/success';
import queryString from 'query-string';
import Error from '../../components/alerts/error';
import SideTransition from './SideTransition';
import APIService from '../../APIService';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import NotFound from '../../assets/images/notFound.png';
import List from '../../components/stackedList/stackedList';

import './media.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Media = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [media, setMedia] = useState([]);
  const pages = [{ name: 'Media', href: '/media', current: true }];
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });

  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);

  const GetMedia = async (params, newMedia) => {
    const result = await APIService.Get('images', { ...params });
    const response = await Promise.allSettled(
      result.data.map(async (file) => {
        // const tags =await ExifReader.load(`${config.webUrl}/${file.path}`)
        return {
          id: file.id,
          name: file.title,
          size: '',
          path: file.path,
          source: `${config.webUrl}/${file.path}`,
          description: file.description,
          current: false,
          information: {
            'Created': moment(file.created_at).format('DD.MM.YYYY HH:MM'),
            // 'Dimensions': `${tags['Image Width'].value} x ${tags['Image Height'].value}`,
            'Number of uses': file.number_of_uses
          },
          author_id: file.author_id
            ? {
                value: file.author_id.id,
                label: file.author_id.full_name
              }
            : null
        };
      })
    ).then((res) => res.map(({ value }) => value));

    if (newMedia) {
      setMedia(response);
      setPage(2);
    } else {
      setMedia([...media, ...response]);
      setPage(page + 1);
    }
    setLoading(false);
  };

  const AddMedia = async () => {
    try {
      setButtonClicked(true);
      const formData = new FormData();

      Array.from(selectedFile).forEach((file) => {
        formData.append('fileName[]', file);
      });
      const result = await APIService.Insert('images', formData);
      setButtonClicked(false);
      setOpenModal(false);
      setAlert({
        header: 'Successfully added!',
        message: 'You have successfully added new media.'
      });
      setShowAlert(true);
      GetMedia({ page: 1 }, true);
      setSelectedFile(null);
    } catch (err) {
      setButtonClicked(false);
      console.error(err);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const handleScroll = () => {
    const element = document.getElementById('main-content');
    if (
      Math.floor(element.scrollTop + element.clientHeight + 2) <
        element.scrollHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  };

  useEffect(() => {
    setLoading(true);
    setMedia([]);
    setPage(1);
    GetMedia({ page: page });

    document
      .getElementById('main-content')
      .addEventListener('scroll', handleScroll);

    return () =>
      document
        .getElementById('main-content')
        ?.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreData();
  }, [isFetching]);

  const fetchMoreData = () => {
    let params = queryString.parse(location.search);

    GetMedia({ page: page, ...(params && { search: params.search }) });
    setIsFetching(false);
  };

  useEffect(() => {
    setLoading(true);
    let params = queryString.parse(location.search);

    GetMedia({ search: params.search, page: 1 }, true);
  }, [location.search]);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <Modal
        style={{ maxWidth: '55rem' }}
        open={openModal}
        setOpen={setOpenModal}
        header="Upload image"
        save={AddMedia}
        valid={selectedFile}
        buttonClicked={buttonClicked}
      >
        <div className="flex items-center">
          <div className="file-upload__parent flex-auto">
            <p>Select an image</p>
            <label htmlFor="file-upload" class="custom-file-upload">
              <i class="fa fa-cloud-upload"></i> Upload
            </label>
            <input
              id="file-upload"
              type="file"
              name="fileName[]"
              multiple
              onChange={(e) => setSelectedFile(e.target.files)}
            />

            <div className="uploaded-images">
              {selectedFile &&
                Array.from(selectedFile).map((file) => (
                  <img key={file} src={URL.createObjectURL(file)} />
                ))}
            </div>
          </div>
        </div>
      </Modal>
      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={() => setOpenModal(true)}>
                  Insert new image
                </AddButton>
              </div>
            </div>

            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              {/* Main content */}
              <div className="flex-1 flex items-stretch overflow-hidden">
                <main className="flex-1 overflow-y-auto">
                  <div className="pt-8 mx-auto sm:px-6 lg:px-8">
                    {/* Gallery */}
                    <section
                      className="mt-8 pb-16"
                      aria-labelledby="gallery-heading"
                    >
                      {media.length ? (
                        <ul
                          role="list"
                          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                        >
                          {media.map((file, index) => (
                            <li
                              key={index}
                              className="relative cursor-pointer h-full"
                            >
                              <div
                                style={{ height: '90%' }}
                                onClick={() => {
                                  setOpen(true);
                                  setCurrentMedia(file);
                                  setMedia(
                                    [...media].map((obj) => {
                                      if (obj?.id === file?.id)
                                        return {
                                          ...obj,
                                          current: true
                                        };
                                      else return { ...obj, current: false };
                                    })
                                  );
                                }}
                                className={classNames(
                                  file?.current
                                    ? 'ring-2 ring-offset-2 ring-indigo-500'
                                    : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
                                  'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden'
                                )}
                              >
                                <img
                                  src={file?.source}
                                  alt=""
                                  className={classNames(
                                    file?.current
                                      ? ''
                                      : 'group-hover:opacity-75',
                                    'object-cover pointer-events-none h-full mx-auto'
                                  )}
                                />
                              </div>
                              <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                                {file?.name}
                              </p>
                              <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                                {file?.size}
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <List url="">
                          <img className="img-not-found" src={NotFound} />
                        </List>
                      )}
                    </section>
                  </div>
                </main>

                <SideTransition
                  open={open}
                  setOpen={setOpen}
                  currentFile={currentMedia}
                  setCurrentMedia={setCurrentMedia}
                  GetMedia={GetMedia}
                  setMedia={setMedia}
                />
                {/* Details sidebar */}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Media;
