import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import axios from 'axios';
import config from '../../config/api.config';

import './select.css';

const defaultAdditional = {
  page: 1
};

const MultiselectAsync = ({
  label,
  option = '',
  url = config.apiUrlBuka,
  setSelectedOption,
  defaultValue
}) => {
  const loadPageOptions = async (q, prevOptions, { page }) => {
    const response = await axios.get(`${url}/${option}`, {
      params: { page, search: q }
    });

    return {
      options: response.data.data.map((d) => {
        return { value: d.id, label: d.name ?? d.title };
      }),
      hasMore: response.data.meta.current_page !== response.data.meta.last_page,

      additional: {
        page: page + 1
      }
    };
  };

  return (
    <div className="w-full mb-3">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <AsyncPaginate
        isMulti
        debounceTimeout={300}
        additional={defaultAdditional}
        loadOptions={loadPageOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        isSearchable={true}
        value={defaultValue}
        onChange={setSelectedOption}
      />
    </div>
  );
};

export default MultiselectAsync;
