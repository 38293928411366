import React, { useState, Fragment, useRef, useEffect } from 'react';
import axios from 'axios';
import ExifReader from 'exifreader';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import { FocusPicker } from 'image-focus';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SelectAsync from '../../components/multiselect/selectAsync';
import { useMediaQuery } from 'react-responsive';

import RUG from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';
import EditButton from '../../components/buttons/EditButton';

import './mediaGallery.css';

import config from '../../config/api.config';
var focus = { x: 0, y: 0 };

const MediaGallery = ({
  open,
  setOpen,
  setMainImage,
  allowMainImage,
  gallery = [],
  setGallery = () => {},
  thumbnail = false
}) => {
  const refImg = useRef(null);
  const rugRef = useRef(null);
  const [search, setSearch] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [state, setState] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  const [imageData, setImageData] = useState({
    type: '',
    name: '',
    width: null,
    height: null,
    size: 0
  });
  const handleCurrentImage = async (image) => {
    setCurrentImage({
      id: image.id,
      source: image.source,
      author_id: image.author_id,
      description: image.description,
      focal_point: { x: '0', y: '0' }
    });
    const tags = await ExifReader.load(image.source);
    setImageData({
      ...imageData,
      id: image.id,
      type: image.file.name.split('.')[1],
      path: image.path,
      name: image.file.name,
      width: tags['Image Width'],
      height: tags['Image Height'],
      size: image.file.size,
      author_id: {
        value: image?.author_id?.id,
        label: image?.author_id?.full_name
      },
      description: image.description
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleChange = (e) =>
    setImageData({ ...imageData, [e.target.name]: e.target.value });

  const GetMedia = async (params, search) => {
    await axios
      .get(`${config.apiUrlBuka}/images`, {
        params: {
          ...params
        }
      })
      .then((res) => {
        const response = res.data.data.map((file) => {
          const form = new FormData();
          form.append('fileName[]', file);

          const img = {
            id: file.id,
            name: file.title,
            size: '',
            source: `${config.webUrl}/${file.path}`,
            file: new File(form, file.title),
            description: file.description,
            author_id: file.author_id,
            done: true
          };
          img.click= () => handleCurrentImage(img)

          return img; 
        });

        if (search) {
          setState(response);
          setPage(2);
        } else {
          rugRef.current?.setState({
            images: [...rugRef.current.state.images, ...response]
          });
          setState([...state, ...response]);
          setPage(page + 1);
        }

        setIsSearching(false);
      })
      .catch((err) => console.error(err));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setIsSearching(true);
      GetMedia({ search: search, page: 1 }, true);
    }
  };

  const EditImage = async () => {
    setButtonClicked(true);
    await axios
      .put(`${config.apiUrlBuka}/images/${imageData?.id}`, {
        title: imageData?.name,
        path: imageData?.path,
        ...(imageData?.description && {description: imageData?.description}),
        author_id: imageData?.author_id?.value
      })
      .then((res) => {
        setButtonClicked(false);
        setEditMode(false);
      })
      .catch((err) => {
        setButtonClicked(false);
        setEditMode(false);
        console.error(err);
      });
  };

  const handleDelete = async (image) => {
    await axios
      .delete(`${config.apiUrlBuka}/images/${image.id}`)
      .then((res) => {})
      .catch((err) => console.error(err));
  };

  const customRequest = ({
    uid,
    file,
    send,
    action,
    headers,
    onProgress,
    onSuccess,
    onError
  }) => {
    const form = new FormData();

    // Object.defineProperty(file, 'name', {
    //   writable: true,
    //   value: file.name.replaceAll(' ', '_')
    // });

    form.append('fileName[]', file);

    setState([...state, form]);
    onProgress(uid, 100);
    onSuccess(uid, form);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .post(`${config.apiUrlBuka}/images`, form, {
        onUploadProgress: ({ total, loaded }) => {
          onProgress(uid, Math.round((loaded / total) * 100));
        },
        cancelToken: source.token
      })
      .then((response) => {
        onSuccess(uid, {
          id: response.data.data[0].id,
          name: response.data.data[0].title,
          size: '',
          source: `${config.webUrl}/${response.data.data[0].path}`
        });

        GetMedia();
      })
      .catch((error) => {
        onError(uid, {
          action,
          status: error.request,
          response: error.response
        });
      });

    return {
      abort() {
        source.cancel();
      }
    };
  };

  useEffect(() => {
    if (refImg.current) {
      const img = refImg.current;
      focus = { x: 0, y: 0 };
      const focusPicker = new FocusPicker(img, {
        focus,
        onChange: (newFocus) => {
          const x = newFocus.x.toFixed(2);
          const y = newFocus.y.toFixed(2);
          setCurrentImage({
            ...currentImage,
            focal_point: { x: parseFloat(x), y: parseFloat(y) }
          });

          var photo = gallery.find((g) => g.id === currentImage.id);
          if (photo) photo.focal_point = { x: parseFloat(x), y: parseFloat(y) };
        }
      });
    }
  }, [refImg.current]);

  const handleScroll = () => {
    const element = document.getElementsByClassName('rug-items')[0];
    if (
      Math.floor(element.scrollTop + element.clientHeight) !==
        element.scrollHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  };

  useEffect(() => {
    if (!isFetching) return;
    GetMedia({ page: page, ...(search && { search: search }) });
    setIsFetching(false);
  }, [isFetching]);

  useEffect(() => {
    GetMedia({ page: page });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementsByClassName('rug-items')[0]
        ?.addEventListener('scroll', handleScroll);
    }, 500);
  }, [state, open]);

  return (
    <Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={alertOpen}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {warningMessage}
              </Alert>
            </Snackbar>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl w-full sm:p-6 sm:pt-10">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-1.5 sm:text-left w-full flex">
                    <div className="mt-2 flex-col flex w-full lg:flex-row">
                      <div
                        style={{
                          width: !isMobile
                            ? currentImage
                              ? 'calc(100% - 20rem)'
                              : '100%'
                            : '100%'
                        }}
                      >
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <SearchIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="text"
                            value={search}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                            placeholder="Search media gallery"
                            style={{ width: '99%' }}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e)}
                          />
                        </div>

                        <RUG
                          key={isSearching}
                          ref={rugRef}
                          customRequest={customRequest}
                          initialState={state}
                          className="py-3 w-full"
                          onClick={(image) =>
                            !image.error && handleCurrentImage(image)
                          }
                          accept={['jpg', 'jpeg']}
                          onDeleted={(image) => handleDelete(image)}
                          onWarning={(type, rules) => {
                            switch (type) {
                              case 'accept':
                                setWarningMessage(
                                  `You can only upload ${rules.accept.join(
                                    ', '
                                  )}`
                                );
                                setAlertOpen(true);
                                break;
                            }
                          }}
                        />

                        {!thumbnail && gallery?.length ? (
                          <div className="selected-gallery mt-3">
                            <p className="font-medium text-sm text-gray-400 border-gray-300 border-b-2 pb-1 w-2/6">
                              Gallery photos
                            </p>
                            <div className="flex mt-3 w-11/12 overflow-x-auto pr-3">
                              {gallery.map((photo) => (
                                <div
                                  key={photo.source}
                                  className="w-36 h-36 bg-gray-700 rounded relative mr-3 bg-center bg-contain bg-no-repeat"
                                  style={{
                                    backgroundImage: `url(${photo.source})`,
                                    minWidth: '9rem'
                                  }}
                                >
                                  <div
                                    className="absolute z-20 w-full h-2/5 rounded"
                                    style={{
                                      background:
                                        'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.7) 29%, transparent)',
                                      backgroundBlendMode: 'multiply'
                                    }}
                                  ></div>
                                  <div
                                    className="absolute bottom-1 right-1 cursor-pointer"
                                    onClick={() =>
                                      setGallery(
                                        gallery.filter(
                                          (g) => g.source !== photo.source
                                        )
                                      )
                                    }
                                  >
                                    <XIcon
                                      className="w-3.5"
                                      style={{ stroke: '#bfbfbf' }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {currentImage ? (
                        <div className="h-full w-full lg:w-80 p-3 lg:border-l-2 border-gray-300 lg:border-solid flex flex-col-reverse lg:flex-col">
                          {!editMode ? (
                            <div className="flex justify-end lg:justify-between pb-3.5 mt-6 lg:mt-0">
                              {allowMainImage ? (
                                <button
                                  id="button-main-image"
                                  type="button"
                                  className="mr-4 inline-flex items-center px-5 py-1.5 border-2 border-transparent shadow-sm text-sm font-medium rounded text-white bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                                  onClick={() => {
                                    setMainImage(currentImage);
                                    setOpen(false);
                                  }}
                                >
                                  <span>Set as main image </span>
                                </button>
                              ) : (
                                <div />
                              )}

                              <button
                                type="button"
                                className="inline-flex items-center px-5 py-1.5 
                                    border border-transparent shadow-sm text-sm font-medium rounded text-white bg-indigo-600-custom focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setEditMode(true)}
                              >
                                <span> Edit image </span>
                              </button>
                            </div>
                          ) : null}

                          <div
                            className="mx-auto max-h-52 flex justify-center rounded bg-gray-200"
                            style={{ width: 'fit-content' }}
                          >
                            <img
                              src={currentImage.source}
                              className="rounded object-contain"
                              style={{ maxHeight: 'inherit' }}
                              ref={refImg}
                              data-focus-x="0"
                              data-focus-y="0"
                            />
                          </div>
                          <div className="mt-3 mb-5">
                            <h2 className="text-base font-medium text-gray-900">
                              <span className="sr-only">Details for </span>
                              {currentImage.name}
                            </h2>
                            <p className="text-sm font-medium text-gray-500">
                              {currentImage.size}
                            </p>
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900 text-sm">
                              Information
                            </h3>
                            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                              <div className="py-2.5 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Type</dt>
                                <dd className="text-gray-900">
                                  {imageData.type}
                                </dd>
                              </div>
                              <div className="py-2.5 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Size</dt>
                                <dd className="text-gray-900">
                                  {imageData.size}
                                </dd>
                              </div>
                              <div className="py-2.5 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Resolution</dt>
                                <dd className="text-gray-900">
                                  {' '}
                                  {imageData.width?.value}x
                                  {imageData.height?.value}
                                </dd>
                              </div>
                              {!editMode ? (
                                <Fragment>
                                  <div className="py-2.5 flex justify-between text-sm font-medium">
                                    <dt className="text-gray-500">Author</dt>
                                    <dd className="text-gray-900">
                                      {imageData?.author_id?.label}
                                    </dd>
                                  </div>
                                </Fragment>
                              ) : null}
                            </dl>
                            {!editMode ? (
                              <Fragment>
                                <div className="pt-2.5 pb-5 text-sm">
                                  <label className="text-gray-500 font-medium">
                                    Description
                                  </label>
                                  <p className="text-gray-700 italic">
                                    {' '}
                                    {imageData?.description}
                                  </p>
                                </div>
                              </Fragment>
                            ) : null}
                          </div>
                          {!editMode && !allowMainImage ? (
                            <div className="flex justify-end">
                              {gallery?.some(
                                (e) => e.source === currentImage.source
                              ) ? (
                                <button
                                  type="button"
                                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  onClick={() =>
                                    setGallery(
                                      gallery?.filter(
                                        (g) => g.source !== currentImage.source
                                      )
                                    )
                                  }
                                >
                                  Remove image
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  onClick={() =>
                                    !thumbnail
                                      ? setGallery([...gallery, currentImage])
                                      : (setGallery([currentImage]),
                                        setOpen(false))
                                  }
                                >
                                  Select image
                                </button>
                              )}
                            </div>
                          ) : null}
                          {editMode && (
                            <Fragment>
                              <div className="mb-2">
                                <SelectAsync
                                  label="Authors"
                                  option="authors"
                                  defaultValue={imageData?.author_id}
                                  setSelectedOption={(value) =>
                                    setImageData({
                                      ...imageData,
                                      author_id: value
                                    })
                                  }
                                />
                              </div>

                              <div>
                                <label
                                  htmlFor="description"
                                  className="sr-only"
                                >
                                  About
                                </label>
                                <textarea
                                  id="description"
                                  name="description"
                                  placeholder="Description"
                                  value={imageData?.description}
                                  onChange={(e) => handleChange(e)}
                                  rows={3}
                                  className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                />
                              </div>
                              <div className="flex  pt-3.5">
                                <EditButton
                                  buttonClicked={buttonClicked}
                                  className="flex-1 w-full"
                                  onClick={() => EditImage()}
                                >
                                  {' '}
                                  Save changes{' '}
                                </EditButton>
                                <button
                                  onClick={() => setEditMode(false)}
                                  type="button"
                                  className="flex-1 ml-3 bg-white py-1 px-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Cancel
                                </button>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
};

export default MediaGallery;
