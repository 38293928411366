import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt from 'jwt-decode';
import { connect } from 'react-redux';
import Input from '../../components/formInput/formInput';
import { setMenuItems } from '../../redux/menu/action';
import SubmitButton from '../../components/buttons/SubmitButton';
import Error from '../../components/alerts/error';

import auth from '../../auth';

const Login = (props) => {
  const history = useHistory();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  // const login = async () => {
  //   await axios
  //     .post(`${config.apiUrl}/authentication/login`, {
  //       username: 'vanja@gmail.com',
  //       password: 'password',
  //       client_id: 2,
  //       client_secret: 'RmOF1w1jl6gYcaUwSPj8hFxA8l1fvpy5SQr9cJsf'
  //     })
  //     .then((res) => {
  //       // console.log(res);
  //       const token = res.data.access_token;
  //       const dataJwt = jwt(res.data.access_token);
  //       props.setMenuItems(res.data.menu_items);
  //       auth.login(
  //         token,
  //         dataJwt.exp,
  //         res.data.user_id,
  //         res.data.user_role[0].name,
  //         (_) => history.push('/dashboard')
  //       );
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       history.push('/login');
  //     });
  // };

  const loginAsTenant = async () => {
    setButtonClicked(true);
    await axios
      .post(`https://6yka.infodesk.io/authentication/login`, {
        username: user.email,
        password: user.password,
        client_id: 1,
        grant_type: 'password',
        client_secret: 'm05hhegaCzpNC5f2BF6gwDrQPfXRPtni5qQgmmez'
      })
      .then((res) => {
        const token = res.data.access_token;
        const dataJwt = jwt(res.data.access_token);
        props.setMenuItems(res.data.menu_items);
        setButtonClicked(false);

        auth.login(
          token,
          dataJwt.exp,
          res.data.user_id,
          res.data.user_role[0].name,
          (_) => history.push('/dashboard')
        );
      })
      .catch((err) => {
        setButtonClicked(false);
        setShowError(true);
        console.error(err);
        err.response.data.error
          ? setError([err.response.data.error])
          : Object.entries(err.response.data.errors).forEach(([key, value]) =>
              setError([...value])
            );
      });
  };

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        loginAsTenant();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [user.email, user.password]);

  return (
    <Fragment>
      <Error show={showError} setShow={setShowError} array={error} />
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Input
              name="email"
              label="Email address"
              type="email"
              className="mb-4"
              value={user.email}
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="password"
              label="Password"
              type="password"
              value={user.password}
              onChange={(e) => handleChange(e)}
            />

            <div className="mt-14 flex justify-center">
              {/* <button
              className="w-4/5 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={login}
            >
              Sign in
            </button> */}
              <SubmitButton
                buttonClicked={buttonClicked}
                valid={true}
                className="w-4/5"
                onClick={loginAsTenant}
              >
                Sign in
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setMenuItems: (menu) => dispatch(setMenuItems(menu))
});

export default connect(null, mapDispatchToProps)(Login);
