import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Loader from 'react-js-loader';

import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import Modal from '../../components/modal/modal';
import FormInput from '../../components/formInput/formInput';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';

import config from '../../config/api.config';

const MenuItems = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [buttonClicked, setButtonClicked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });

  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });

  const GetMenuItems = async (params) => {
    await axios
      .get(`${config.apiUrl}/menu-items`, {
        params: {
          ...params
        }
      })
      .then((res) => {
        console.log(res); //vraca samo menu items za central app
        setLoading(false);
        setMenuItems(res.data.data);
        const { current_page, last_page, per_page, total } = res.data.meta;

        setParams({
          current_page,
          last_page,
          per_page,
          total
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const AddMenuItem = async () => {
    setButtonClicked(true);
    await axios
      .post(`${config.apiUrl}/men-items`, {})
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setAlert({
          header: 'Successfully added!',
          message: 'You have successfully added new menu item.'
        });
        GetMenuItems({ page: params.current_page });
      })
      .catch((err) => {
        setButtonClicked(false);
        console.error(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    GetMenuItems({ page: 1 });
  }, []);

  return <Fragment></Fragment>;
};

export default MenuItems;
