export class ApiConfig {
  constructor() {
    this.webUrl = process.env.REACT_APP_WEB_URL;
    this.publicUrl = process.env.PUBLIC_URL;
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.apiUrlBuka = process.env.REACT_APP_API_URL_BUKA;
  }
}
const config = new ApiConfig();
export default config;
