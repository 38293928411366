import React from 'react';
import DatePicker from 'react-datepicker';

const DatetimePicker = ({ label, date, setDate, className, ...otherProps }) => {
  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">
        <DatePicker
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          selected={date}
          onChange={(date) => setDate(date)}
          showTimeSelect
          autoComplete="off"
          dateFormat="MMMM d, yyyy h:mm aa"
          {...otherProps}
        />
      </div>
    </div>
  );
};

export default DatetimePicker;
