import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './checkbox.css';

const CustomCheckbox = ({ checked, name, label, handleChange, className }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name={name}
          color="primary"
        />
      }
      label={label}
      className={`checkbox ${className}`}
    />
  );
};

export default CustomCheckbox;
