export class CustomH4 {
  constructor({ data }) {
    this.data = data;
  }

  // static get toolbox() {
  //     return {
  //       title: 'H4',
  //       icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.8em" height="1.8em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M11 17H9v-4H5v4H3V7h2v4h4V7h2v10zm10-2h-1v2h-2v-2h-5v-2l4.05-6H20v6h1v2zm-3-2V9l-2.79 4H18z" fill="currentColor"/></svg>'
  //     };
  //   }

  render() {
    const h4 = document.createElement('input');
    h4.placeholder = this.data?.placeholder ?? '';
    h4.style.fontSize = '1em';
    h4.style.padding = '0.7em 0';
    h4.style.border = 'none';
    h4.style.outline = 'none';
    h4.style.width = '100%';
    h4.value = this.data && this.data.text ? this.data.text : '';

    return h4;
  }

  save(blockContent) {
    return {
      text: blockContent.value
    };
  }
}
