import React from 'react';
import { PlusSmIcon } from '@heroicons/react/outline';

import './buttons.css';

const AddButton = ({ children, className, onClick }) => {
  return (
    <button
      type="button"
      className={`${className} button--add relative inline-flex items-center px-4 py-2 
        border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 
         focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      onClick={onClick}
    >
      <PlusSmIcon className="h-6 w-6" />
      <span> {children} </span>
    </button>
  );
};

export default AddButton;
