import React, { Fragment, useEffect, useState } from 'react';
import Loader from 'react-js-loader';
import APIService from '../../APIService';
import { useHistory } from 'react-router-dom';
import AddButton from '../../components/buttons/AddButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import List from '../../components/stackedList/stackedList';
import Success from '../../components/alerts/success';
import DeleteModal from '../../components/deleteModal/deleteModal';
import Pagination from '../../components/pagination/pagination';
import NotFound from '../../assets/images/notFound.png';

const Articles = () => {
  const history = useHistory();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [categories, setCategories] = useState([]);
  const pages = [{ name: 'Categories', href: '/categories', current: true }];
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({
    id: null,
    title: '',
    excerpt: ''
  });
  const [params, setParams] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  });
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });

  const getPageNumber = (newPage) => {
    setParams({ ...params, current_page: newPage });
    GetCategories({
      page: newPage
    });
  };

  const DeleteCategory = async () => {
    const result = await APIService.Delete(category.id, 'categories');
    setAlert({
      header: 'Successfully deleted!',
      message: 'This location is no longer available.'
    });
    setShowAlert(true);
    GetCategories({ page: params.current_page });
  };

  const GetCategories = async (params) => {
    const result = await APIService.Get('categories', { ...params });
    setLoading(false);
    setCategories(result.data);
    const { current_page, last_page, per_page, total } = result.meta;
    setParams({
      current_page,
      last_page,
      per_page,
      total
    });
  };

  useEffect(() => {
    setLoading(true);
    GetCategories({ page: 1 });
  }, []);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header="Delete category"
        deleteFun={DeleteCategory}
      >
        Are you sure you want to delete this category? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>

      <div className="p-4 h-full">
        {loading ? (
          <div className="h-full flex items-center justify-center">
            <Loader type="bubble-scale" bgColor={'#3f51b5'} size={100} />
          </div>
        ) : (
          <Fragment>
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
              <Breadcrumb pages={pages} />
              <div className="flex-shrink-0">
                <AddButton onClick={() => history.push('/add-category')}>
                  Create new category
                </AddButton>
              </div>
            </div>
            <div
              className="relative pb-20"
              style={{ height: 'fit-content', minHeight: '90%' }}
            >
              {/* <Table/> */}
              <List url="">
                <Fragment>
                  {categories.length ? (
                    categories.map((category) => (
                      <li key={category.id}>
                        <div className="block hover:bg-gray-50 flex justify-between">
                          <div
                            className="px-4 py-5 flex items-center sm:px-6 w-full"
                            onClick={() => {
                              setCategory(category);
                              history.push(`/edit-category/${category.id}`);
                            }}
                          >
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="font-medium text-gray-600 truncate">
                                    {category.title}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="ml-5 flex-shrink-0 text-indigo-600 hover:text-indigo-900 text-sm font-medium cursor-pointer">
                              {' '}
                              Edit{' '}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setCategory(category);
                              setOpenDeleteModal(true);
                            }}
                            className="px-4 py-5 flex items-center sm:px-6 text-sm font-medium text-red-600 hover:text-red-800 cursor-pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <img className="img-not-found" src={NotFound} />
                  )}
                </Fragment>
              </List>
              <Pagination params={params} getPageNumber={getPageNumber} />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Articles;
