import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import APIService from '../../APIService';
import ContainerWrapper from '../../layout/ContainerWrapper/ContainerWrapper';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import SelectAsync from '../../components/multiselect/selectAsync';

import Select from '../../components/multiselect/select';
import Accordion from '../../components/accordion/accordion';
import Checkbox from '../../components/checkbox/checkbox';
import Input from '../../components/formInput/formInput';
import Textbox from '../../components/formInput/textbox';
import DatetimePicker from '../../components/datetimePicker/datetimePicker';
import DeleteButton from '../../components/buttons/DeleteButton';
import Success from '../../components/alerts/success';
import EditButton from '../../components/buttons/EditButton';
import DeleteModal from '../../components/deleteModal/deleteModal';
import ColorPicker from '../../components/colorPicker/colorPicker';
import MediaGallery from '../../components/mediaGallery/mediaGallery';
import SubmitButton from '../../components/buttons/SubmitButton';
import Seo from '../../components/seo/seoAccordion';
import Error from '../../components/alerts/error';
import AddButton from '../../components/buttons/AddButton';
import { ReactComponent as Upload } from '../../assets/images/uploadImage.svg';
import '../../assets/css/unreset.scss';

const AddCategory = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [publishedDate, setPublishedDate] = useState(new Date());
  const [expiredDate, setExpiredDate] = useState();
  const [unpublishedDate, setUnpublishedDate] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showAside, setShowAside] = useState(false);

  const [category, setCategory] = useState({
    id: null,
    title: '',
    excerpt: '',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    slug: '',
    canonical_url: '',
    template_id: '',
    parent_category: null,
    rss_feed: null,
    main_category_article_id: null
  });
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [colorPicker, setColorPicker] = useState('');
  const [parentCategories, setParentCategories] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [image, setImage] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);

  const pages = [
    { name: 'Categories', href: '/categories', current: false },
    {
      name: location.pathname.includes('edit-category')
        ? 'Edit category'
        : 'Add category',
      href: location.pathname.includes('edit-category')
        ? `/edit-category/${params.id}`
        : '/add-category',
      current: true
    }
  ];

  const [dynamicAttributes, setDynamicAttributes] = useState([
    {
      checked: false,
      name: 'ad_free',
      label: 'Ad free'
    },
    {
      checked: true,
      name: 'menu_item',
      label: 'Menu item'
    }
  ]);

  const handleChange = (event) => {
    setDynamicAttributes(
      [...dynamicAttributes].map((att) => {
        if (att.name === event.target.name) {
          return {
            ...att,
            checked: event.target.checked
          };
        } else return att;
      })
    );
  };

  const handleState = (e) =>
    setCategory({ ...category, [e.target.name]: e.target.value });

  const renderDynamicAttributes = () =>
    dynamicAttributes.map((att) => (
      <Checkbox
        key={att.name}
        checked={att.checked}
        name={att.name}
        label={att.label}
        handleChange={handleChange}
      />
    ));

  const AddCategory = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Insert('categories', {
        title: category.title,
        color_code: colorPicker.hex ?? null,
        excerpt: category.excerpt,
        published_at: publishedDate,
        unpublished_at: unpublishedDate ?? null,
        expires_at: expiredDate,
        parent_category_id: category.parent_category?.value ?? null,
        settings: Object.fromEntries(
          dynamicAttributes.map((att) => [att.name, att.checked])
        ),
        seo_title: category.seo_title,
        seo_description: category.seo_description,
        seo_keywords: category.seo_keywords,
        template_id: category.template_id?.value,
        main_category_article_id:
          category.main_category_article_id?.value ?? null,
        thumbnail_image: {
          type: 'media',
          data: {
            type: 'image',
            files: [
              {
                id: image[0]?.id,
                url: image[0]?.source,
                author: image[0]?.author_id?.label,
                description: image[0]?.description,
                focal_point: image[0]?.focal_point
              }
            ]
          }
        }
      });
      setButtonClicked(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully added!',
        message: 'You have successfully added new category.'
      });
      history.push('/categories');
    } catch (err) {
      setButtonClicked(false);
      console.error(err);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const EditCategory = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Update(category.id, 'categories', {
        title: category.title,
        color_code: colorPicker.hex,
        excerpt: category.excerpt,
        published_at: publishedDate,
        unpublished_at: unpublishedDate,
        expires_at: expiredDate,
        parent_category_id: category.parent_category?.value ?? null,
        settings: Object.fromEntries(
          dynamicAttributes.map((att) => [att.name, att.checked])
        ),
        seo_title: category.seo_title,
        seo_description: category.seo_description,
        seo_keywords: category.seo_keywords,
        template_id: category.template_id?.value,
        main_category_article_id: category.main_category_article_id?.value,
        thumbnail_image: {
          type: 'media',
          data: {
            type: 'image',
            files: [
              {
                id: image[0]?.id,
                url: image[0]?.source,
                author: image[0]?.author_id?.label,
                description: image[0]?.description,
                focal_point: image[0]?.focal_point
              }
            ]
          }
        }
      });
      setButtonClicked(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully edited!',
        message: 'You have successfully edit category.'
      });
      setTimeout((_) => history.push('/categories'), 1000);
    } catch (err) {
      setButtonClicked(false);
      console.error(err);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const DeleteCategory = async () => {
    try {
      const result = await APIService.Delete(category.id, 'categories');
      setAlert({
        header: 'Successfully deleted!',
        message: 'This category is no longer available.'
      });
      setShowAlert(true);
      setTimeout((_) => history.push('/categories'), 1000);
    } catch (err) {
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const GetCategoryCreate = async () => {
    const result = await APIService.Get('categories/create');
    setParentCategories(
      result.categories.map((cat) => {
        return { value: cat.id, label: cat.title };
      })
    );
    setTemplates(
      result.templates.map((temp) => {
        return { value: temp.id, label: temp.title };
      })
    );
  };

  const GetCategoryById = async (id) => {
    const result = await APIService.GetById(`${id}/edit`, 'categories');
    const cat = result.category;
    setCategory({
      id: cat.id,
      title: cat.title,
      excerpt: cat.excerpt,
      seo_title: cat.seo?.title,
      seo_description: cat.seo?.description,
      seo_keywords: cat.seo?.keywords,
      slug: cat.slug,
      canonical_url: cat.seo ? JSON.parse(cat.seo.canonical_url).data : null,
      template_id: { value: cat.template?.id, label: cat.template?.title },
      parent_category: cat.parent_category
        ? {
            value: cat.parent_category.id,
            label: cat.parent_category.title
          }
        : null,
      rss_feed: null,
      main_category_article_id: cat.main_category_article_id
        ? {
            value: cat.main_category_article_id.id,
            label: cat.main_category_article_id.title
          }
        : null
    });

    setImage([JSON.parse(cat.thumbnail_image)]);
  };
  useEffect(() => {
    GetCategoryCreate();

    if (params.id) GetCategoryById(params.id);
  }, []);

  useEffect(() => {
    if (!params.id)
      setCategory({
        ...category,
        template_id: templates.find((a) => a.label === 'Standard')
      });
  }, [templates]);

  useEffect(() => {
    if (category.title && category.template_id && category.seo_title)
      setValid(true);
    else setValid(false);
  }, [category.title, category.template_id, category.seo_title]);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        header="Delete category"
        deleteFun={DeleteCategory}
      >
        Are you sure you want to delete this category? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <div className="-ml-4 lg:-mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
        <Breadcrumb pages={pages} />
        <AddButton
          className="lg:hidden"
          onClick={() => setShowAside(!showAside)}
        />
      </div>
      <div className="flex-1 flex">
        <ContainerWrapper
          className="p-9 pt-12"
          style={{ flexDirection: 'row', justifyContent: 'space-around' }}
        >
          <div style={{ width: '46%' }}>
            <Input
              name="title"
              label="Title*"
              type="text"
              className="mb-8"
              value={category.title}
              onChange={(e) => {
                setCategory({
                  ...category,
                  title: e.target.value,
                  seo_title: e.target.value
                });
              }}
            />
            <Textbox
              name="excerpt"
              label="Excerpt"
              type="text"
              className="mb-8"
              value={category.excerpt}
              onChange={(e) => {
                setCategory({
                  ...category,
                  excerpt: e.target.value,
                  seo_description: e.target.value
                });
              }}
            />
            <SelectAsync
              className="mb-8"
              label="Main category article"
              option="articles"
              params={{ category: category?.id }}
              defaultValue={category?.main_category_article_id}
              setSelectedOption={(value) =>
                setCategory({ ...category, main_category_article_id: value })
              }
            />
          </div>
          <div className="" style={{ width: '46%' }}>
            <div className="mb-7">
              <label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                Choose category color
              </label>
              <ColorPicker setColor={setColorPicker} />
            </div>
            <Select
              label="Parent category"
              options={parentCategories}
              defaultValue={category?.parent_category}
              setSelectedOption={(value) =>
                setCategory({ ...category, parent_category: value })
              }
            />
          </div>
        </ContainerWrapper>
        <aside
          className={`${
            isMobile && showAside
              ? 'block absolute z-10 h-full right-0'
              : 'hidden lg:block ml-6'
          }  w-96 overflow-x-hidden bg-white shadow rounded-lg`}
        >
          {location.pathname.includes('edit-category') ? (
            <div className="flex justify-between pt-3.5 px-4">
              <DeleteButton
                className="mr-6"
                onClick={() => {
                  setCategory(category);
                  setOpenModal(true);
                }}
              >
                Delete category
              </DeleteButton>
              <EditButton
                buttonClicked={buttonClicked}
                valid={valid}
                onClick={EditCategory}
              >
                Save changes
              </EditButton>
            </div>
          ) : (
            <div className="flex justify-end pt-3.5 px-4">
              <SubmitButton
                buttonClicked={buttonClicked}
                valid={valid}
                onClick={AddCategory}
              >
                Add category
              </SubmitButton>
            </div>
          )}
          <Accordion header="ATTRIBUTES" expanded={true}>
            <Select
              label="Template*"
              options={templates}
              defaultValue={category.template_id}
              setSelectedOption={(value) =>
                setCategory({ ...category, template_id: value })
              }
            />
            {/* <MultiselectAsync 
              label="RSS feed"
              option="articles"
              setSelectedOption={(value) =>
                setCategory({ ...category, rss_feed: value })
              } /> */}
          </Accordion>

          <Accordion header="DYNAMIC ATTRIBUTES" expandIcon={true}>
            {renderDynamicAttributes()}
          </Accordion>
          <Accordion header="OPTIONS" expandIcon={true}>
            <DatetimePicker
              className="mb-3"
              label="Unpublished at"
              name="unpublishedDate"
              date={unpublishedDate}
              setDate={setUnpublishedDate}
            />
            <DatetimePicker
              className="mb-3"
              label="Published at"
              date={publishedDate}
              setDate={setPublishedDate}
            />
            <DatetimePicker
              className="mb-3"
              label="Expires at"
              date={expiredDate}
              setDate={setExpiredDate}
            />
          </Accordion>
          <Accordion header="SEO OPTIMIZATON" expandIcon={true}>
            <Seo data={category} handleState={handleState} />
            {image.length ? (
              <div
                style={{ width: '100%', marginBottom: '14px' }}
                onClick={() => setOpenGallery(true)}
              >
                <img className="w-full" src={image[0]?.source} />
                <p className="border border-gray-300 text-center rounded-b-md mb-3 text-sm py-2 font-medium cursor-pointer hover:text-gray-500">
                  Change image
                </p>
              </div>
            ) : (
              <button
                className="main-image__button"
                onClick={() => setOpenGallery(true)}
              >
                <Upload />
                Select image
              </button>
            )}
            <MediaGallery
              open={openGallery}
              setOpen={setOpenGallery}
              setMainImage={() => {}}
              allowMainImage={false}
              gallery={image}
              setGallery={setImage}
              thumbnail={true}
              setAllImages={() => {}}
            />

            {category.id ? (
              <Input
                label="Slug"
                name="slug"
                className="mb-3"
                value={category.slug}
                handleChange={handleState}
              />
            ) : null}
            {category.id ? (
              <p className="block text-sm text-gray-500 mb-4">
                Canonical URL:{' '}
                <span className="text-gray-900 font-medium">
                  {category.canonical_url}
                </span>
              </p>
            ) : null}
          </Accordion>
        </aside>
      </div>
    </Fragment>
  );
};

export default AddCategory;
