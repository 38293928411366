import React from 'react';

const FormInput = ({ handleChange, name, label, className, ...otherProps }) => {
  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          autoComplete="off"
          id={name}
          name={name}
          onChange={handleChange}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          {...otherProps}
        />
      </div>
    </div>
  );
};

export default FormInput;
