import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

import './articlesPosition.css';

const ArticlesPosition = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const generateArticle = (number) => (
    <div
      key={number}
      className="border-2 border-dashed border-gray-300 w-36 h-16 mx-1.5 my-3 flex justify-center items-center text-gray-300 text-xl"
    >
      {number}
    </div>
  );

  return (
    <div>
      <Button
        aria-describedby={id}
        className="articles-position__button"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        See articles position
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className="articles-position"
      >
        <div className="flex m-2">
          <div>{[1, 2, 3].map((el) => generateArticle(el))}</div>
          <div>
            <div
              className="border-2 border-dashed border-gray-300 w-75 mx-1.5 mt-3 flex justify-center items-center text-gray-300 text-xl"
              style={{ height: '8.75rem' }}
            >
              4
            </div>
            <div className="flex">
              {[5, 6].map((el) => generateArticle(el))}
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ArticlesPosition;
