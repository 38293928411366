import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuAlt2Icon, PlusIcon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';

import ProfileDropdown from './profileDropdown';

const Header = ({ setMobileMenuOpen }) => {
  const history = useHistory();
  const location = useLocation();
  const [searchState, setSearch] = useState('');

  const handleKeyPress = (event) => {
    const { name, value } = event?.target;

    if (event.key === 'Enter') {
      const _params = new URLSearchParams({ [name]: value });
      history.replace({
        pathname: location.pathname,
        search: searchState ? _params.toString() : ''
      });
    }
  };

  return (
    <header className="w-full relative z-10">
      <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <div className="flex-1 flex">
            <div className="w-full flex md:ml-0">
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                  <SearchIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
                <input
                  name="search"
                  id="search"
                  className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400"
                  placeholder="Search"
                  type="search"
                  value={searchState}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </div>
            </div>
          </div>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <button
              type="button"
              className="flex bg-indigo-600-custom p-1 rounded-full items-center justify-center text-white hover:bg-indigo-600-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-indigo-600-hover"
              onClick={() => history.push('/add-article')}
            >
              <PlusIcon className="h-6 w-6" aria-hidden="true" />
              <span className="sr-only">Add article</span>
            </button>
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
