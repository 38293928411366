import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import APIService from '../../APIService';
import ContainerWrapper from '../../layout/ContainerWrapper/ContainerWrapper';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Seo from '../../components/seo/seoAccordion';
import Select from '../../components/multiselect/select';
import Multiselect from '../../components/multiselect/multiselect';
import Accordion from '../../components/accordion/accordion';
import Checkbox from '../../components/checkbox/checkbox';
import Input from '../../components/formInput/formInput';
import Textbox from '../../components/formInput/textbox';
import DatetimePicker from '../../components/datetimePicker/datetimePicker';
import DeleteButton from '../../components/buttons/DeleteButton';
import Success from '../../components/alerts/success';
import EditButton from '../../components/buttons/EditButton';
import DeleteModal from '../../components/deleteModal/deleteModal';
import SubmitButton from '../../components/buttons/SubmitButton';
import Error from '../../components/alerts/error';
import AddButton from '../../components/buttons/AddButton';
import { ReactComponent as ProfileImg } from '../../assets/images/editProfile.svg';
import Profile from '../../assets/images/profile.png';
import './author.css';

const AddAuthor = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const fileRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showAside, setShowAside] = useState(false);

  const [publishedDate, setPublishedDate] = useState(new Date());
  const [expiredDate, setExpiredDate] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [author, setAuthor] = useState({
    id: null,
    full_name: '',
    excerpt: '',
    columnist: false,
    social_links: [],
    social_links_selected: [],
    template_id: null,
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    photo: null,
    photoThumb: null,
    accountActive: false,
    slug: null
  });
  const [alert, setAlert] = useState({
    header: '',
    message: ''
  });
  const [templates, setTemplates] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const pages = [
    { name: 'Authors', href: '/authors', current: false },
    {
      name: location.pathname.includes('edit-author')
        ? 'Edit author'
        : 'Add author',
      href: location.pathname.includes('edit-author')
        ? `/edit-author/${params.id}`
        : '/add-author',
      current: true
    }
  ];

  const handleState = (e) =>
    setAuthor({ ...author, [e.target.name]: e.target.value });

  const AddAuthor = async () => {
    try {
      setButtonClicked(true);
      const result = await APIService.Insert('authors', {
        full_name: author.full_name,
        excerpt: author.excerpt,
        published_at: publishedDate,
        unpublished_at: !author.accountActive ? new Date() : null,
        expires_at: expiredDate,
        seo_title: author.seo_title,
        seo_description: author.seo_description,
        seo_keywords: author.seo_keywords,
        social_links: Object.fromEntries(
          author.social_links_selected.map((att) => [att.label, att.value])
        ),
        columnist: author.columnist ? 1 : 0,
        template_id: author.template_id?.value
      });

      if (author.photo instanceof File) {
        const formData = new FormData();
        formData.append('photo', author.photo);
        formData.append('_method', 'PUT');

        const result = await APIService.Insert(
          `authors/${result.data.id}/photo-update`,
          formData
        );
        setButtonClicked(false);
        setShowAlert(true);
        setAlert({
          header: 'Successfully added!',
          message: 'You have successfully added new author.'
        });
        history.push('/authors');
      } else {
        setButtonClicked(false);
        setShowAlert(true);
        setAlert({
          header: 'Successfully added!',
          message: 'You have successfully added new author.'
        });
        history.push('/authors');
      }
    } catch (err) {
      setButtonClicked(false);
      console.error(err);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const GetAuthorCreate = async () => {
    const result = await APIService.Get('authors/create');
    setTemplates(
      result.templates.map((temp) => {
        return { value: temp.id, label: temp.title };
      })
    );
    setSocialNetworks(
      result.socialNetworks.map((net) => {
        return { value: net.url, label: net.title };
      })
    );
  };

  const DeleteAuthor = async () => {
    const result = await APIService.Delete(author.id, 'authors');
    setAlert({
      header: 'Successfully deleted!',
      message: 'This author is no longer available.'
    });
    setShowAlert(true);
    setTimeout((_) => history.push('/authors'), 1000);
  };

  const GetAuthorById = async (id) => {
    const result = await APIService.GetById(`${id}/edit`, 'authors');
    const author = result.author;
    setAuthor({
      id: author.id,
      full_name: author.full_name,
      excerpt: author.excerpt ?? '',
      columnist: author.columnist,
      social_links: author.social_links
        ? Object.entries(JSON.parse(author.social_links)).map(
            ([key, value]) => {
              return { value: value, label: key };
            }
          )
        : [],
      social_links_selected: author.social_links
        ? Object.entries(JSON.parse(author.social_links)).map(
            ([key, value]) => {
              return { value: value, label: key };
            }
          )
        : [],
      accountActive: author.unpublished_at ? false : true,
      published_at: author.published_at,
      expires_at: author.expires_at,
      seo_title: author.seo?.title,
      seo_description: author.seo?.description,
      seo_keywords: author.seo?.keywords,
      canonical_url: author.seo
        ? JSON.parse(author.seo?.canonical_url).data
        : null,
      template_id: {
        value: author.template?.id,
        label: author.template?.title
      },
      photo: author.photo,
      photoThumb: author.photo
        ? `https://6yka.infodesk.io/${author.photo.path}`
        : null,
      slug: author.slug
    });
  };

  const EditAuthor = async () => {
    setButtonClicked(true);
    if (author.photo instanceof File) {
      const formData = new FormData();
      formData.append('photo', author.photo);
      formData.append('_method', 'PUT');
      const result = await APIService.Insert(
        `authors/${author.id}/photo-update`,
        formData
      );
    }

    try {
      const result = await APIService.Update(author.id, 'authors', {
        full_name: author.full_name,
        excerpt: author.excerpt,
        published_at: publishedDate,
        unpublished_at: !author.accountActive ? new Date() : null,
        expires_at: expiredDate,
        seo_title: author.seo_title,
        seo_description: author.seo_description,
        seo_keywords: author.seo_keywords,
        social_links: Object.fromEntries(
          author.social_links_selected.map((att) => [att.label, att.value])
        ),
        columnist: author.columnist ? 1 : 0,
        template_id: author.template_id?.value,
        slug: author.slug
      });
      setButtonClicked(false);
      setShowAlert(true);
      setAlert({
        header: 'Successfully edited!',
        message: 'You have successfully edit author.'
      });
      setTimeout((_) => history.push('/authors'), 1000);
    } catch (err) {
      setButtonClicked(false);
      console.error(err);
      setShowError(true);
      Object.entries(err.response.data.errors).forEach(([key, value]) =>
        setError([...value])
      );
    }
  };

  const changeProfileImage = (e) => {
    var reader = new FileReader();
    reader.addEventListener(
      'load',
      function () {
        setAuthor({
          ...author,
          photo: e.target.files[0],
          photoThumb: reader.result
        });
      },
      false
    );

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    GetAuthorCreate();
    if (params.id) GetAuthorById(params.id);
  }, []);

  useEffect(() => {
    if (!params.id)
      setAuthor({
        ...author,
        template_id: templates.find((a) => a.label === 'Standard')
      });
  }, [templates]);

  useEffect(() => {
    if (author.full_name && author.seo_title && author.template_id) {
      if (
        author.social_links_selected?.length &&
        author.social_links_selected.some((att) => att.value === '')
      )
        setValid(false);
      else setValid(true);
    } else setValid(false);
  }, [
    author.full_name,
    author.seo_title,
    author.template_id,
    author.social_links_selected[0]?.value
  ]);

  return (
    <Fragment>
      <Success show={showAlert} setShow={setShowAlert} header={alert.header}>
        {alert.message}
      </Success>
      <Error show={showError} setShow={setShowError} array={error} />
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        header="Delete author"
        deleteFun={DeleteAuthor}
      >
        Are you sure you want to delete this author? It will be permanently
        removed. This action cannot be undone.
      </DeleteModal>
      <div className="-ml-4 lg:-mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-3">
        <Breadcrumb pages={pages} />
        <AddButton
          className="lg:hidden"
          onClick={() => setShowAside(!showAside)}
        />
      </div>
      <div className="flex-1 flex">
        <ContainerWrapper
          className="p-9 pt-12"
          style={{ flexDirection: 'row', justifyContent: 'space-around' }}
        >
          <div className="" style={{ width: '46%' }}>
            <div
              className="profile-settings__image"
              onClick={() => fileRef.current.click()}
            >
              <input
                type="file"
                accept="image/*"
                ref={fileRef}
                onChange={changeProfileImage}
              />
              <ProfileImg />
              <img src={author.photoThumb ?? Profile} />
              <p>CHANGE PROFILE PICTURE</p>
            </div>

            <Input
              name="full_name"
              label="Name*"
              type="text"
              className="mb-8"
              value={author.full_name}
              onChange={(e) =>
                setAuthor({
                  ...author,
                  full_name: e.target.value,
                  seo_title: e.target.value
                })
              }
            />
            <Textbox
              name="excerpt"
              label="Excerpt"
              autoComplete="excerpt"
              type="text"
              className="mb-8"
              value={author.excerpt}
              onChange={(e) =>
                setAuthor({
                  ...author,
                  excerpt: e.target.value,
                  seo_description: e.target.value
                })
              }
            />
          </div>
          <div className="" style={{ width: '46%' }}>
            <Checkbox
              className="mb-6"
              checked={author.columnist}
              name="columnist"
              label="Columnist"
              handleChange={(event) =>
                setAuthor({ ...author, columnist: event.target.checked })
              }
            />
            <Multiselect
              className="mb-5"
              label="Social links"
              options={socialNetworks}
              defaultValue={author.social_links}
              setSelectedOption={(value) =>
                setAuthor({
                  ...author,
                  social_links: value,
                  social_links_selected:
                    value.length > author.social_links_selected?.length
                      ? [
                          ...author.social_links_selected,
                          { value: '', label: value.slice(-1)[0].label }
                        ]
                      : author.social_links_selected?.filter((el) =>
                          value.some((v) => v.label === el.label)
                        )
                })
              }
            />
            <div>
              {author.social_links?.map((net) => (
                <Input
                  name={net.label}
                  label={net.label}
                  type="text"
                  className="mb-3"
                  placeholder={`${net.label} link`}
                  value={
                    author.social_links_selected?.find(
                      (el) => el.label === net.label
                    )?.value
                  }
                  onChange={(e) => {
                    let temp = author.social_links_selected;
                    if (temp)
                      temp.find((el) => el.label === e.target.name).value =
                        e.target.value;
                    setAuthor({ ...author, social_links_selected: temp });
                  }}
                />
              ))}
            </div>
          </div>
        </ContainerWrapper>
        <aside
          className={`${
            isMobile && showAside
              ? 'block absolute z-10 h-full right-0'
              : 'hidden lg:block ml-6'
          }  w-96 overflow-x-hidden bg-white shadow rounded-lg`}
        >
          {location.pathname.includes('edit-author') ? (
            <div className="flex justify-between pt-3.5 px-4">
              <DeleteButton
                className="mr-6"
                onClick={() => {
                  setAuthor(author);
                  setOpenModal(true);
                }}
              >
                Delete author
              </DeleteButton>
              <EditButton
                buttonClicked={buttonClicked}
                valid={valid}
                onClick={EditAuthor}
              >
                Save changes
              </EditButton>
            </div>
          ) : (
            <div className="flex justify-end pt-3.5 px-4">
              <SubmitButton
                buttonClicked={buttonClicked}
                valid={valid}
                onClick={AddAuthor}
              >
                Add author
              </SubmitButton>
            </div>
          )}
          <Accordion header="ATTRIBUTES" expanded={true}>
            <Select
              label="Template*"
              options={templates}
              defaultValue={author.template_id}
              setSelectedOption={(value) =>
                setAuthor({ ...author, template_id: value })
              }
            />
          </Accordion>
          <Accordion header="OPTIONS" expanded={true}>
            <Checkbox
              className="mb-3"
              checked={author.accountActive}
              name="active"
              label="Active"
              handleChange={(event) =>
                setAuthor({ ...author, accountActive: event.target.checked })
              }
            />
            <DatetimePicker
              className="mb-3"
              label="Published at"
              date={publishedDate}
              setDate={setPublishedDate}
            />
            <DatetimePicker
              className="mb-3"
              label="Expires at"
              date={expiredDate}
              setDate={setExpiredDate}
            />
          </Accordion>
          <Accordion header="SEO OPTIMIZATON" expandIcon={true}>
            <Seo data={author} handleState={handleState} />
            {author.id ? (
              <>
                <Input
                  label="Slug"
                  name="slug"
                  className="mb-3"
                  value={author.slug}
                  handleChange={handleState}
                />
                <p className="block text-sm text-gray-500 mb-4">
                  Canonical URL:{' '}
                  <span className="text-gray-900 font-medium">
                    {author.canonical_url}
                  </span>
                </p>
              </>
            ) : null}
          </Accordion>
        </aside>
      </div>
    </Fragment>
  );
};

export default AddAuthor;
